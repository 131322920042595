var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import FunctionalButton from "../components/FunctionalButton";
import TextField, { ETextFieldType } from "../components/TextField";
import CrossIcon from "../components/icons/CrossIcon";
import useAuth from '../tools/useAuth';
import axios, { AxiosError } from "axios";
import { AVAILABLE_LANGUAGES, AVAILABLE_TIMES } from "../Config";
function CreateSessionPage(props) {
    const [availableDecks, setAvailableDecks] = useState([]);
    const [validationError, setValidationError] = useState("");
    const { email, userId } = useAuth();
    const refTitle = useRef();
    const refDate = useRef(new Date());
    const refTime = useRef("10:00");
    const refDeck = useRef();
    const refLanguage = useRef();
    const refComment = useRef();
    function createSession_(infos) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield axios.post(`${process.env.REACT_APP_API_URL}/sessions`, infos);
                if (props.onValidate)
                    props.onValidate(infos);
            }
            catch (e) {
                if (e instanceof AxiosError) {
                    setValidationError((_b = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error);
                }
            }
        });
    }
    function createSession() {
        var _a, _b, _c;
        let dto = {
            Title: (_a = refTitle.current) === null || _a === void 0 ? void 0 : _a.value,
            Date: new Date(refDate.current),
            Deck: (_b = availableDecks.find(el => el.name === refDeck.current)) === null || _b === void 0 ? void 0 : _b.deckId,
            Language: refLanguage.current,
            Comment: (_c = refComment.current) === null || _c === void 0 ? void 0 : _c.value,
            Animator: userId
        };
        const splitTime = refTime.current.split(':');
        const hours = parseInt(splitTime[0]);
        const minutes = parseInt(splitTime[1]);
        dto.Date.setHours(hours, minutes, 0, 0);
        if (dto.Title && dto.Date && dto.Deck && dto.Language && dto.Comment) {
            createSession_(dto);
        }
        else {
            setValidationError("Vous devez remplir tous les champs pour créer une session"); // TODO move texts to a global file ?
        }
    }
    function getDecks() {
        return __awaiter(this, void 0, void 0, function* () {
            const route = `${process.env.REACT_APP_API_URL}/decks`;
            const response = yield axios.get(route);
            return response.data;
        });
    }
    useEffect(() => {
        getDecks().then((decks_) => {
            setAvailableDecks(decks_);
        });
    }, []);
    return (_jsxs("div", Object.assign({ className: "flex justify-center fixed w-full h-full" }, { children: [_jsx("div", Object.assign({ className: "fixed h-full flex items-center justify-center", style: {
                    zIndex: 1,
                    padding: "12px 0px",
                    width: "500px",
                } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col", style: {
                        background: "white",
                        borderColor: "black",
                        borderWidth: "2px",
                        padding: "21px",
                        gap: '16px'
                    } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("div", Object.assign({ className: "font-roboto", style: { fontSize: '18px', color: '#54659F', fontWeight: '700' } }, { children: "NOUVELLE SESSION" })), _jsx("button", Object.assign({ onClick: props.onClose }, { children: _jsx(CrossIcon, {}) }))] })), _jsx("div", Object.assign({ style: { color: 'red', width: '300px' } }, { children: validationError })), _jsx(TextField, { parentRef: refTitle, label: "Titre", type: ETextFieldType.SINGLELINE_TEXT }), _jsxs("div", Object.assign({ className: "flex flex-row", style: { gap: '16px' } }, { children: [_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(TextField, { value: refDate.current, parentRef: refDate, label: "Date", type: ETextFieldType.DATE }) })), _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(TextField, { value: refTime.current, dropdownOptions: AVAILABLE_TIMES, parentRef: refTime, label: "Heure", type: ETextFieldType.DROPDOWN }) }))] })), _jsx(TextField, { dropdownOptions: availableDecks.map(el => el.name), parentRef: refDeck, label: "Deck", type: ETextFieldType.DROPDOWN }), _jsx(TextField, { dropdownOptions: AVAILABLE_LANGUAGES, parentRef: refLanguage, label: "Langue", type: ETextFieldType.DROPDOWN }), _jsx(TextField, { parentRef: refComment, label: "Commentaire", type: ETextFieldType.MULTILINE_TEXT }), _jsxs("div", Object.assign({ className: "flex flex-row justify-end", style: { gap: '16px', height: '50px' } }, { children: [_jsx(FunctionalButton, { colorMode: 1, isRound: false, text: "Annuler", onClick: props.onClose }), _jsx(FunctionalButton, { colorMode: 0, isRound: false, text: "Cr\u00E9er la session", onClick: createSession })] }))] })) })), _jsx("div", { className: "fixed right-0 w-full h-full", style: {
                    opacity: 0.8,
                    background: "#54659F",
                } })] })));
}
export default CreateSessionPage;
