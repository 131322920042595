import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import "../App.css";
import "../App.tailwind.css";
import MenuBar from "../components/MenuBar";
import UserRoleTag from "../components/UserRoleTag";
import ArrowRightCircleIcon from "../components/icons/ArrowRightCircle";
import SearchBar from "../components/SearchBar";
import useAuth from "../tools/useAuth";
import { getUsersList } from "../services/UsersRequests";
function UserItem(props) {
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3", style: { borderBottom: '1px solid #A1AABC7F', padding: '20px 17px' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col flex-grow" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsx("div", Object.assign({ className: "text-dilemmeDeepPurpple font-roboto font-medium", style: { fontSize: '18px', marginRight: '10px' } }, { children: props.userInfos.Name })), _jsx("div", Object.assign({ style: { fontWeight: 'bold', fontSize: '14px' } }, { children: props.userInfos.Structure }))] })), _jsx("div", Object.assign({ className: "flex flex-row" }, { children: _jsx("div", Object.assign({ className: "font-roboto", style: { fontSize: '14px', fontWeight: 'normal', marginRight: '10px' } }, { children: props.userInfos.Email })) }))] })), _jsx(UserRoleTag, { role: props.userInfos.Role })] })));
}
function UsersPage() {
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { token } = useAuth();
    function navigateToUserFront() {
        window.open("https://userfront.com/", "_blank");
    }
    useEffect(() => {
        getUsersList().then((data) => {
            const sortedData = data.sort((a, b) => { return a.Name.localeCompare(b.Name); });
            setUsers(sortedData);
            setFilteredUsers(sortedData);
        });
    }, []);
    function handleSearchChanged(search) {
        let newList = users.filter((item) => {
            var _a;
            return item.Name.toLowerCase().includes(search.toLowerCase()) || ((_a = item.Structure) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase()));
        });
        setFilteredUsers(newList);
    }
    return (_jsx("div", Object.assign({ className: "h-full w-screen bg-white flex flex-col right-0", style: {
            zIndex: -1,
        } }, { children: _jsxs("div", Object.assign({ className: "h-screen w-screen bg-white flex absolute  flex-col", style: {
                zIndex: -1,
            } }, { children: [_jsx(MenuBar, { setOpenResetPassword: setOpenResetPassword, selectedTab: 2 }), _jsxs("div", Object.assign({ className: "flex flex-col items-stretch", style: { width: '920px', alignSelf: 'center' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between", style: { margin: '32px 0px' } }, { children: [_jsxs("button", Object.assign({ className: "flex flex-row items-center justify-center", style: {
                                        backgroundImage: "url(./assets/UI/Button-userfront.png)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundClip: "content-box",
                                        backgroundSize: "contain",
                                        width: "200px",
                                        height: "50px",
                                    }, onClick: navigateToUserFront }, { children: [_jsx("div", Object.assign({ className: "flex self-center", style: { width: '144px', color: 'white', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'bold', } }, { children: "ACCEDER A USERFRONT" })), _jsx(ArrowRightCircleIcon, {})] })), _jsx("div", Object.assign({ className: "" }, { children: _jsx(SearchBar, { placeholder: "Rechercher un utilisateur", onInputChanged: handleSearchChanged }) }))] })), _jsx("div", { className: "w-full", style: {
                                backgroundImage: "url(./assets/UI/Ligne-crayon.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundClip: "content-box",
                                backgroundSize: "contain",
                                height: "4px"
                            } }), filteredUsers.map((item, index) => _jsx("div", Object.assign({ style: { background: (index % 2 === 0 ? 'transparent' : '#A1AABC33') } }, { children: _jsx(UserItem, { userInfos: item }) }), "UserItem" + index))] }))] })) })));
}
export default UsersPage;
