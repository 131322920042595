import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import LoopIcon from "./icons/LoopIcon";
const SearchBar = (props) => {
    function handleInputChange(event) {
        if (props.onInputChanged) {
            props.onInputChanged(event.target.value);
        }
    }
    const [focusInput, setFocusInput] = useState(false);
    function onFocus() {
        setFocusInput(true);
    }
    function onBlur() {
        setFocusInput(false);
    }
    const borderColor = focusInput ? "#54659F" : "transparent";
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center", style: {
            padding: '10px', width: '460px', height: '49px', background: '#F5F5FA', borderRadius: '40px',
            borderWidth: "1px", borderColor: borderColor
        } }, { children: [_jsx(LoopIcon, {}), _jsx("input", { onFocus: onFocus, onBlur: onBlur, className: "searchBarInput w-full", type: "text", placeholder: props.placeholder, name: "search", style: { marginLeft: '8px', background: 'transparent' }, onChange: handleInputChange })] })));
};
SearchBar.defaultProps = {
    placeholder: 'Rechercher...',
    onInputChanged: undefined
};
export default SearchBar;
