var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import "../App.css";
import "../App.tailwind.css";
import Userfront from "@userfront/core";
import { CheckPasswordCriteria, CheckPassword_Length, CheckPassword_LowerCase, CheckPassword_OneDigit, CheckPassword_SpecialCharacter, CheckPassword_UpperCase } from "../tools/PasswordCheck";
import CrossIcon from "../components/icons/CrossIcon";
import EyeIcon from "../components/icons/EyeIcon";
import EyeClosedIcon from "../components/icons/EyeClosedIcon";
import RoundedFunctionalButton from "../components/FunctionalButton";
function TemporaryPasswordUpdatePage(props) {
    const refHolder = useRef();
    const refOldPasswordInput = useRef();
    const refConfirmPasswordInput = useRef();
    const refPasswordInput = useRef();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [errorPasswordCriteria, setErrorPasswordCiriteria] = useState(false);
    const [errorPasswordMatch, setErrorPasswordMatch] = useState(false);
    const [errorOldPassword, setErrorOldPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // Create a new URLSearchParams object to parse query parameters
    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
    function handleShowConfirmationPassword() {
        setShowConfirmationPassword(!showConfirmationPassword);
    }
    function handleShowOldPassword() {
        setShowOldPassword(!showOldPassword);
    }
    function handleCheckPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            if (CheckPasswordCriteria(password)) {
                if (password === confirmPassword) {
                    if (Userfront.tokens.accessToken !== undefined && Userfront.tokens.accessToken !== null) {
                        console.log("token", Userfront.tokens.accessToken);
                        yield Userfront.updatePassword({
                            method: "jwt",
                            existingPassword: oldPassword,
                            password: password,
                            redirect: false,
                        }).then(response => {
                            console.log(response, 'response');
                            props.setOpenResetPassword(false);
                        }).catch((err) => {
                            console.log(err, 'err');
                            setErrorOldPassword(true);
                            setErrorPasswordCiriteria(false);
                            setErrorPasswordMatch(false);
                        });
                        console.log(password, 'password');
                    }
                    else {
                        console.log("no token");
                    }
                }
                else {
                    console.log("passwords don't match");
                    setErrorPasswordMatch(true);
                    setErrorOldPassword(false);
                    setErrorPasswordCiriteria(false);
                }
            }
            else {
                console.log("passwords criteria not respected");
                setErrorPasswordMatch(false);
                setErrorOldPassword(false);
                setErrorPasswordCiriteria(true);
            }
        });
    }
    function handleCancel() {
        setErrorMessage("");
        setPassword("");
        setOldPassword("");
        setConfirmPassword("");
        setErrorOldPassword(false);
        setErrorPasswordMatch(false);
        setErrorPasswordCiriteria(false);
        props.setOpenResetPassword(false);
    }
    useEffect(() => {
        if (errorPasswordCriteria) {
            refHolder.current.style.height = "591px";
            setErrorMessage("Le nouveau mot de passe ne remplit pas toutes les conditions");
        }
        else if (!errorPasswordCriteria && !errorOldPassword && !errorPasswordMatch) {
            refHolder.current.style.height = "535px";
            setErrorMessage("");
        }
        else if (errorOldPassword) {
            refHolder.current.style.height = "575px";
            setErrorMessage("Le mot de passe actuel est incorrect");
        }
        else if (errorPasswordMatch) {
            refHolder.current.style.height = "575px";
            setErrorMessage("Les deux mots de passe ne correspondent pas");
        }
    }, [errorOldPassword, errorPasswordCriteria, errorPasswordMatch]);
    useEffect(() => {
        let result = CheckPasswordCriteria(password);
        console.log(`password : ${password} CheckPasswordCriteria${result} length${CheckPassword_Length(password)} lower${CheckPassword_LowerCase(password)} upper${CheckPassword_UpperCase(password)} special${CheckPassword_SpecialCharacter(password)} `);
    }, [password]);
    useEffect(() => {
        setErrorOldPassword(false);
        setErrorPasswordMatch(false);
        setErrorPasswordCiriteria(false);
    }, [props.setOpenResetPassword]);
    return (_jsxs("div", Object.assign({ className: " w-screen h-screen flex absolute justify-center", style: {
            opacity: "1",
            zIndex: '10',
        } }, { children: [_jsx("div", { className: "flex absolute bg-dilemmeDeepPurpple w-full h-full ", style: {
                    opacity: "0.5",
                } }), _jsx("div", Object.assign({ className: "flex absolute w-full h-full justify-center" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col relative items-center bg-white justify-start", ref: refHolder, style: {
                        width: "428px",
                        // same
                        top: "7.45%",
                        padding: "32px",
                        gap: "24px",
                        border: "2px solid #000000",
                        // opacity: "1",
                    } }, { children: [_jsxs("div", Object.assign({ className: "flex relative text-dilemmeDeepPurpple font-roboto justify-center text-center", style: {
                                width: "100%",
                                height: "auto",
                                fontSize: "18px",
                                lineHeight: "normal",
                                fontWeight: "700",
                                textTransform: "uppercase",
                            } }, { children: ["modifier le mot de passe", _jsx("button", Object.assign({ className: "flex absolute justify-center items-center", style: {
                                        left: "95%"
                                    }, onClick: handleCancel }, { children: _jsx(CrossIcon, {}) }))] })), (errorPasswordCriteria || errorOldPassword || errorPasswordMatch) &&
                            _jsx("div", Object.assign({ className: "flex relative text-teamRed font-roboto justify-center items-center text-center", style: {
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "16px",
                                } }, { children: errorMessage })), _jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                width: "100%",
                                gap: "8px",
                            } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                        width: "100%",
                                        fontSize: "14px",
                                        lineHeight: "normal",
                                        fontWeight: "600",
                                    } }, { children: "Mot de passe" })), _jsxs("div", Object.assign({ className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                        width: "100%",
                                        height: "auto",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        paddingTop: "8px",
                                        paddingBottom: "8px",
                                        border: "1px solid #A1AABC",
                                        borderRadius: "4px",
                                    } }, { children: [_jsx("input", { ref: refOldPasswordInput, id: "oldPassword", name: "oldPassword", type: showOldPassword ? "text" : "password", autoComplete: "current-password", required: true, value: oldPassword, onChange: value => setOldPassword(value.target.value), 
                                            //use pattern for password when creating the account
                                            className: "flex absolute font-roboto text-black bg-brokenWhite", style: {
                                                left: "0%",
                                                top: "0%",
                                                paddingLeft: "10px",
                                                paddingRight: "9.46%",
                                                paddingTop: "8px",
                                                paddingBottom: "8px",
                                                height: "100%",
                                                width: "100%",
                                                borderRadius: "4px",
                                            } }), _jsx("button", Object.assign({ onClick: handleShowOldPassword, className: "flex relative", style: {
                                                height: "100%",
                                                width: "7.08%",
                                                left: "94%",
                                            } }, { children: showOldPassword ? _jsx(EyeIcon, { color: "black" }) : _jsx(EyeClosedIcon, { color: "black" }) }))] }))] })), _jsxs("div", Object.assign({ className: "flex-col flex relative font-roboto text-black", style: {
                                width: "100%",
                                // height: "18.50%",    /* 65px */
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontStyle: "normal",
                                listStyle: "disc"
                            } }, { children: ["Veuillez saisir votre mot de passe. Il doit contenir au moins :", _jsx("li", Object.assign({ style: { color: `${CheckPassword_Length(password) ? "green" : "red"}` } }, { children: "8 caract\u00E8res" })), _jsx("li", Object.assign({ style: CheckPassword_UpperCase(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 majuscule" })), _jsx("li", Object.assign({ style: CheckPassword_LowerCase(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 minuscule" })), _jsx("li", Object.assign({ style: CheckPassword_OneDigit(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 chiffre" })), _jsx("li", Object.assign({ style: CheckPassword_SpecialCharacter(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 caract\u00E8re sp\u00E9cial" }))] })), _jsxs("div", Object.assign({ className: "flex flex-col relative", style: {
                                width: "100%",
                                gap: "16px"
                            } }, { children: [_jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                        width: "100%",
                                        gap: "8px",
                                    } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                                width: "100%",
                                                fontSize: "14px",
                                                lineHeight: "normal",
                                                fontWeight: "600",
                                            } }, { children: "Nouveau mot de passe" })), _jsxs("div", Object.assign({ className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                                width: "100%",
                                                height: "auto",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                paddingTop: "8px",
                                                paddingBottom: "8px",
                                                border: "1px solid #A1AABC",
                                                borderRadius: "4px",
                                            } }, { children: [_jsx("input", { ref: refPasswordInput, id: "password", name: "Password", type: showPassword ? "text" : "password", autoComplete: "current-password", required: true, pattern: "(?=^.{8,}$)(?=.*\\W+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$                                ", value: password, onChange: value => setPassword(value.target.value), 
                                                    //use pattern for password when creating the account
                                                    className: "flex absolute font-roboto text-black bg-brokenWhite", style: {
                                                        left: "0%",
                                                        top: "0%",
                                                        paddingLeft: "10px",
                                                        paddingRight: "9.46%",
                                                        paddingTop: "8px",
                                                        paddingBottom: "8px",
                                                        height: "100%",
                                                        width: "100%",
                                                        borderRadius: "4px",
                                                    } }), _jsx("button", Object.assign({ onClick: handleShowPassword, className: "flex relative", style: {
                                                        height: "100%",
                                                        width: "7.08%",
                                                        left: "94%",
                                                    } }, { children: showPassword ? _jsx(EyeIcon, { color: "black" }) : _jsx(EyeClosedIcon, { color: "black" }) }))] }))] })), _jsx("div", Object.assign({ className: "flex flex-col relative", style: {
                                        width: "100%",
                                        gap: "16px"
                                    } }, { children: _jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                            width: "100%",
                                            gap: "8px",
                                        } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    lineHeight: "normal",
                                                    fontWeight: "600",
                                                } }, { children: "Confirmation nouveau mot de passe" })), _jsxs("div", Object.assign({ className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                                    width: "100%",
                                                    height: "auto",
                                                    paddingLeft: "10px",
                                                    paddingRight: "10px",
                                                    paddingTop: "8px",
                                                    paddingBottom: "8px",
                                                    border: "1px solid #A1AABC",
                                                    borderRadius: "4px",
                                                } }, { children: [_jsx("input", { ref: refConfirmPasswordInput, id: "confirmationPassword", name: "confirmationPassword", type: showConfirmationPassword ? "text" : "password", autoComplete: "current-password", required: true, pattern: "(?=^.{8,}$)(?=.*\\W+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$                                ", value: confirmPassword, onChange: value => setConfirmPassword(value.target.value), 
                                                        //use pattern for password when creating the account
                                                        className: "flex absolute font-roboto text-black bg-brokenWhite", style: {
                                                            left: "0%",
                                                            top: "0%",
                                                            paddingLeft: "10px",
                                                            paddingRight: "9.46%",
                                                            paddingTop: "8px",
                                                            paddingBottom: "8px",
                                                            height: "100%",
                                                            width: "100%",
                                                            borderRadius: "4px",
                                                        } }), _jsx("button", Object.assign({ onClick: handleShowConfirmationPassword, className: "flex relative", style: {
                                                            height: "100%",
                                                            width: "7.08%",
                                                            left: "94%",
                                                        } }, { children: showConfirmationPassword ? _jsx(EyeIcon, { color: "black" }) : _jsx(EyeClosedIcon, { color: "black" }) }))] }))] })) }))] })), _jsxs("div", Object.assign({ className: "flex flex-row-reverse justify-evenly w-full" }, { children: [_jsx(RoundedFunctionalButton, { fontSize: "14px", text: "Modifier le mot de passe", colorMode: 0, showLeftIcon: false, onClick: handleCheckPassword }), _jsx(RoundedFunctionalButton, { fontSize: "14px", text: "Annuler", colorMode: 1, showLeftIcon: false, onClick: handleCancel })] }))] })) }))] })));
}
export default TemporaryPasswordUpdatePage;
