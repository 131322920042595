import { jsx as _jsx } from "react/jsx-runtime";
import ESessionStatus from "cresus-common/dist/dto/ESessionStatus";
function SessionStatusTag(props) {
    let backgroundColor = "#FCB35B";
    let textColor = "black";
    if (props.type === ESessionStatus.TODAY || props.type === ESessionStatus.AVAILABLE) {
        backgroundColor = "#47B969";
        textColor = "white";
    }
    if (props.type === ESessionStatus.NONE) {
        return _jsx("div", {});
    }
    let text = "";
    switch (props.type) {
        case ESessionStatus.ON_GOING:
            text = "En cours";
            break;
        case ESessionStatus.TODAY:
            text = "Aujourd'hui";
            break;
        case ESessionStatus.AVAILABLE:
            text = "Disponible";
            break;
        default:
            break;
    }
    return (_jsx("div", Object.assign({ className: "flex items-center justify-center self-center", style: { padding: '1px 5px', fontSize: '10px', fontFamily: 'Roboto', fontWeight: 'bold', borderRadius: '2px',
            color: textColor, background: backgroundColor } }, { children: text })));
}
export default SessionStatusTag;
