import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function CheckBoxComponent(props) {
    const name = props.name;
    function handleChange(event) {
        if (props.onChange) {
            props.onChange(event.target.checked);
        }
    }
    return (_jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [_jsx("input", { type: "checkbox", defaultChecked: true, id: props.name, name: props.name, onChange: handleChange }), _jsx("div", Object.assign({ className: "font-roboto items-center flex relative", style: {
                    fontSize: "14px",
                    fontWeight: "400",
                    fontStyle: "normal",
                    lineHeight: "normal",
                } }, { children: name }))] })));
}
export default CheckBoxComponent;
