import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import isNullOrUndefined from "../tools/isNullOrUndefined";
function SelectionCardComponent(props) {
    return (_jsx("div", Object.assign({ className: "absolute w-full" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col relative gap-4 w-full h-auto overflow-y-auto", style: {
                top: '-1px',
                padding: '16px',
                border: '1px solid #000000',
                visibility: `${props.selectedType === props.typeCards ? "visible" : "hidden"}`,
                zIndex: `${props.selectedType === props.typeCards ? "100" : "5"}`,
                // borderTop:'none',
            } }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between relative w-full" }, { children: [isNullOrUndefined(props.tagToFilterComponents) ? null :
                            _jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsx("div", Object.assign({ className: "font-roboto", style: {
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                        } }, { children: "Th\u00E9matiques" })), _jsx("div", Object.assign({ className: "flex relative flex-wrap gap-2" }, { children: props.tagToFilterComponents.map((TagToFilterComponent, index) => {
                                            return TagToFilterComponent;
                                        }) }))] })), isNullOrUndefined(props.checkBoxComponents) ? null :
                            _jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsx("div", Object.assign({ className: "font-roboto", style: {
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                        } }, { children: "Niveau de difficult\u00E9" })), _jsx("div", Object.assign({ className: "flex gap-6 flex-nowrap" }, { children: props.checkBoxComponents }))] }))] })), props.cardsComponents.map((card, index) => { return (card); })] })) })));
}
export default SelectionCardComponent;
