"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTimeFromDate = void 0;
function formatTimeFromDate(date) {
    if (!date)
        return "XX:XX";
    const formatOpts = {
        minimumIntegerDigits: 2
    };
    return `${date.getHours().toLocaleString('fr-FR', formatOpts)}:${date.getMinutes().toLocaleString('fr-FR', formatOpts)}`;
}
exports.formatTimeFromDate = formatTimeFromDate;
