var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import FunctionalButton from "../components/FunctionalButton";
import TextField, { ETextFieldType } from "../components/TextField";
import CrossIcon from "../components/icons/CrossIcon";
// import CreateSessionDTO from 'cresus-common/dist/dto/CreateSessionDTO';
// import { DatePickerHandle } from "react-widgets/cjs/DatePicker";
// import { WidgetHandle } from "react-widgets/cjs";
import useAuth from '../tools/useAuth';
import EUserRole from 'cresus-common/dist/dto/EUserRole';
import AVAILABLE_TIMES from "../tools/AvailableTimes";
import { getUsersList } from "../services/UsersRequests";
import axios, { AxiosError } from "axios";
import { AVAILABLE_LANGUAGES } from "../Config";
import { formatTimeFromDate } from "cresus-common/dist/utils/DateUtils";
function ModifySessionPage(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const [validationError, setValidationError] = useState("");
    const { role } = useAuth();
    const [availableAnimators, setAvailableAnimators] = useState([]);
    const [availableDecks, setAvailableDecks] = useState([]);
    const refTitle = useRef();
    const refDate = useRef((_a = props.sessionInfos) === null || _a === void 0 ? void 0 : _a.Date);
    const refTime = useRef(formatTimeFromDate(new Date((_b = props.sessionInfos) === null || _b === void 0 ? void 0 : _b.Date)));
    const refAnimator = useRef((_c = props.sessionInfos) === null || _c === void 0 ? void 0 : _c.AnimatorName);
    const refDeck = useRef(); // TODO default value
    const refLanguage = useRef((_d = props.sessionInfos) === null || _d === void 0 ? void 0 : _d.Language);
    const refComment = useRef();
    const [defaultDeckValue, setDefaultDeckValue] = useState();
    function saveSessionChanges() {
        var _a, _b, _c, _d, _e, _f;
        return __awaiter(this, void 0, void 0, function* () {
            const previousData = props.sessionInfos;
            const dto = {
                Id: previousData.Id,
                Name: (_a = refTitle.current) === null || _a === void 0 ? void 0 : _a.value,
                Date: new Date(refDate.current),
                AnimatorId: (_b = availableAnimators.find(el => el.Name === refAnimator.current)) === null || _b === void 0 ? void 0 : _b.UserId,
                DeckId: (_c = availableDecks.find(el => el.name === refDeck.current)) === null || _c === void 0 ? void 0 : _c.deckId,
                Language: refLanguage.current,
                Comment: (_d = refComment.current) === null || _d === void 0 ? void 0 : _d.value
            };
            const splitTime = refTime.current.split(':');
            const hours = parseInt(splitTime[0]);
            const minutes = parseInt(splitTime[1]);
            dto.Date.setHours(hours, minutes, 0, 0);
            // alert(JSON.stringify(dto));
            if (dto.Name && dto.AnimatorId && dto.Date && dto.DeckId && dto.Language && dto.Comment) {
                try {
                    yield axios.put(`${process.env.REACT_APP_API_URL}/sessions`, dto);
                    if (props.onValidate)
                        props.onValidate();
                }
                catch (e) {
                    if (e instanceof AxiosError) {
                        setValidationError((_f = (_e = e.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.error);
                    }
                }
            }
            else {
                setValidationError("Vous devez remplir tous les champs pour créer une session");
            }
        });
    }
    function getDecks(animatorId) {
        return __awaiter(this, void 0, void 0, function* () {
            const route = `${process.env.REACT_APP_API_URL}/decks/${animatorId}`;
            const response = yield axios.get(route);
            return response.data;
        });
    }
    function getDropdownsData() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const users = yield getUsersList();
            setAvailableAnimators(users);
            const animatorId = (_a = users.find(el => el.Name === refAnimator.current)) === null || _a === void 0 ? void 0 : _a.UserId; // TODO if a user is removed this can lead to a crash
            const decks = yield getDecks(animatorId);
            let foundDeck = decks.find(el => { var _a; return el.deckId === ((_a = props.sessionInfos) === null || _a === void 0 ? void 0 : _a.DeckId); });
            if (foundDeck)
                refDeck.current = foundDeck.name;
            else
                refDeck.current = decks[0].name; // TODO a bit dirty as we assume default deck is always on 0
            console.log(decks);
            setDefaultDeckValue(refDeck.current);
            setAvailableDecks(decks);
        });
    }
    function onAnimatorChange(animatorName) {
        console.log('animator changed', animatorName);
        getDropdownsData();
    }
    useEffect(() => {
        getDropdownsData();
    }, [refAnimator.current]);
    const animatorLink = `${process.env.REACT_APP_FRONTEND_URL}/?sessionId=${props.sessionInfos.Id}&isAnimator=true`;
    const coanimatorLink = `${process.env.REACT_APP_FRONTEND_URL}/?sessionId=${props.sessionInfos.Id}&isCoAnimator=true`;
    const playerLink = `${process.env.REACT_APP_FRONTEND_URL}/?sessionId=${props.sessionInfos.Id}`;
    const playerCode = props.sessionInfos.Code;
    return (_jsxs("div", Object.assign({ className: "flex justify-center fixed w-full h-full" }, { children: [_jsx("div", Object.assign({ className: "fixed h-full flex items-center justify-center", style: {
                    zIndex: 1,
                    padding: "12px 0px",
                    width: "500px",
                } }, { children: _jsxs("div", Object.assign({ className: "flex relative flex-col overflow-auto", style: {
                        height: "95%",
                        background: "white",
                        borderColor: "black",
                        borderWidth: "2px",
                        padding: "21px",
                        gap: '16px'
                    } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between" }, { children: [_jsx("div", Object.assign({ className: "font-roboto", style: { fontSize: '18px', color: '#54659F', fontWeight: '700' } }, { children: "INFORMATIONS DE LA SESSION" })), _jsx("button", Object.assign({ onClick: props.onClose }, { children: _jsx(CrossIcon, {}) }))] })), _jsx("div", Object.assign({ style: { color: 'red' } }, { children: validationError })), _jsx(TextField, { parentRef: refTitle, label: "Titre", value: (_e = props.sessionInfos) === null || _e === void 0 ? void 0 : _e.Name, type: ETextFieldType.SINGLELINE_TEXT }), _jsx(TextField, { label: "Lien de la session - Animateur", value: animatorLink, type: ETextFieldType.COPY_TEXT }), _jsx(TextField, { label: "Lien de la session - CoAnimateur", value: coanimatorLink, type: ETextFieldType.COPY_TEXT }), _jsx(TextField, { label: "Lien de la session - Joueurs", value: playerLink, type: ETextFieldType.COPY_TEXT }), _jsx(TextField, { label: "Code d'accès - Joueurs", value: playerCode, type: ETextFieldType.COPY_TEXT }), _jsxs("div", Object.assign({ className: "flex flex-row", style: { gap: '16px' } }, { children: [_jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(TextField, { value: new Date((_f = props.sessionInfos) === null || _f === void 0 ? void 0 : _f.Date), parentRef: refDate, label: "Date", type: ETextFieldType.DATE }) })), _jsx("div", Object.assign({ className: "w-full" }, { children: _jsx(TextField, { value: refTime.current, dropdownOptions: AVAILABLE_TIMES, parentRef: refTime, label: "Heure", type: ETextFieldType.DROPDOWN }) }))] })), role === EUserRole.ADMINISTRATOR &&
                            _jsx(TextField, { value: (_g = props.sessionInfos) === null || _g === void 0 ? void 0 : _g.AnimatorName, onChange: onAnimatorChange, dropdownOptions: availableAnimators.map(u => u.Name), parentRef: refAnimator, label: "Animateur", type: ETextFieldType.DROPDOWN }), defaultDeckValue &&
                            _jsx(TextField, { value: defaultDeckValue, dropdownOptions: availableDecks.map(el => el.name), parentRef: refDeck, label: "Deck", type: ETextFieldType.DROPDOWN }), _jsx(TextField, { value: (_h = props.sessionInfos) === null || _h === void 0 ? void 0 : _h.Language, dropdownOptions: AVAILABLE_LANGUAGES, parentRef: refLanguage, label: "Langue", type: ETextFieldType.DROPDOWN }), _jsx(TextField, { value: (_j = props.sessionInfos) === null || _j === void 0 ? void 0 : _j.Comment, parentRef: refComment, label: "Commentaire", type: ETextFieldType.MULTILINE_TEXT }), _jsxs("div", Object.assign({ className: "flex flex-row justify-end", style: { gap: '16px', height: '50px' } }, { children: [_jsx(FunctionalButton, { colorMode: 1, isRound: false, text: "Annuler", onClick: props.onClose }), _jsx(FunctionalButton, { colorMode: 0, isRound: false, text: "Enregistrer les modifications", onClick: saveSessionChanges })] }))] })) })), _jsx("div", { className: "fixed right-0 w-full h-full", style: {
                    opacity: 0.8,
                    background: "#54659F",
                } })] })));
}
export default ModifySessionPage;
