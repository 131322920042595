var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import MenuBar from "../components/MenuBar";
import PlusCircleIcon from "../components/icons/PlusCircle";
import SearchBar from "../components/SearchBar";
import GearIcon from "../components/icons/GearIcon";
import TrashIcon from "../components/icons/TrashIcon";
import FunctionalButton from "../components/FunctionalButton";
import EyeIcon from "../components/icons/EyeIcon";
import ContentTypeTag from "../components/ContentTypeTag";
import GeneralToaster from "../components/Toasters/GeneralToaster";
import useAuth from "../tools/useAuth";
import ContentCreationComponent from "../components/ContentCreationComponent";
import ContentVisualisation from "../components/ContentVisualisation";
import axios from "axios";
import EUserRole from "cresus-common/dist/dto/EUserRole";
function ContentItem(props) {
    function showContent() {
        props.setOpenContent(true);
        props.setContent(props.constentInfos);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3", style: { borderBottom: '1px solid #A1AABC7F', padding: '20px 17px' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col flex-grow" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsx("div", Object.assign({ className: "text-dilemmeDeepPurpple font-roboto font-medium", style: { fontSize: '18px', marginRight: '10px' } }, { children: props.constentInfos.name })), _jsx(ContentTypeTag, { typeContent: props.constentInfos.type })] })), _jsx("div", Object.assign({ className: "flex flex-row" }, { children: _jsx("div", { className: "font-roboto", style: { fontSize: '14px', fontWeight: 'normal', marginRight: '10px' } }) }))] })), props.role === EUserRole.ADMINISTRATOR && _jsx("button", Object.assign({ onClick: () => { props.setIsToModify(true); props.setOpenContentModification(true); props.setContent(props.constentInfos); }, style: { width: '30px', height: '30px', background: 'black', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx(GearIcon, {}) })), props.role === EUserRole.ADMINISTRATOR && _jsx("button", Object.assign({ onClick: () => { props.setOpenToaster(true); props.setContent(props.constentInfos); }, style: { width: '30px', height: '30px', background: 'black', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx(TrashIcon, {}) })), _jsx(FunctionalButton, { fontSize: "12px", text: "Visualiser", colorMode: 0, icon: EyeIcon, showLeftIcon: true, onClick: showContent })] })));
}
function ContentsPage() {
    const { role } = useAuth();
    const [shouldUpdateContentList, setShouldUpdateContentList] = useState(false);
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [toasterOpen, setToasterOpen] = useState(false);
    const [content, setContent] = useState();
    const [openNewContentCreation, setOpenNewContentCreation] = useState(false);
    const [isToModify, setIsToModify] = useState(false);
    const [openContentVisualisation, setOpenContentVisualisation] = useState(false);
    // const [contents, setContents] = useState<ContentToExchangeImage[]>([]);
    const [contents, setContents] = useState([]);
    const [filteredContents, setFilteredContents] = useState([]);
    const [contentModel, setContentModel] = useState();
    function handleSearchChanged(search) {
        let newList = contents.filter((item) => {
            return item.name.toLowerCase().includes(search.toLowerCase());
        });
        setFilteredContents(newList);
    }
    function deleteContent(content) {
        return __awaiter(this, void 0, void 0, function* () {
            yield axios.delete(`${process.env.REACT_APP_API_URL}/contents?fileName=${encodeURIComponent(content.filename)}`); // TODO check errors
            updatContentList();
        });
    }
    function getContentList() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(`${process.env.REACT_APP_API_URL}/contents`);
            return response.data;
        });
    }
    function updatContentList() {
        getContentList().then((data) => {
            setContents(data);
            setFilteredContents(data);
        });
    }
    useEffect(() => {
        if (!openNewContentCreation)
            setContentModel(undefined);
    }, [openNewContentCreation]);
    useEffect(() => {
        updatContentList();
    }, []);
    useEffect(() => {
        updatContentList();
        setShouldUpdateContentList(false);
    }, [shouldUpdateContentList]);
    return (_jsx("div", Object.assign({ className: "h-full w-screen bg-white flex flex-col right-0", style: {
            zIndex: -1,
        } }, { children: _jsxs("div", Object.assign({ className: "h-screen w-screen bg-white  absolute flex flex-col", style: {
                zIndex: -1,
            } }, { children: [toasterOpen && _jsx(GeneralToaster, { message: "Souhaitez-vous supprimer le contenu suivant ?", name: content === null || content === void 0 ? void 0 : content.content.name, title: "Supprimer le contenu", buttonTextCancel: "Annuler", buttonTextValidate: "Supprimer", onValidate: () => { deleteContent(contentModel); setToasterOpen(false); }, onCancel: () => { setToasterOpen(false); }, onClose: () => { setToasterOpen(false); } }), _jsx(MenuBar, { setOpenResetPassword: setOpenResetPassword, selectedTab: 3 }), openNewContentCreation && _jsx(ContentCreationComponent, { setShouldUpdateContentList: setShouldUpdateContentList, setOpenContentVisualisation: setOpenContentVisualisation, isToModify: isToModify, onClose: () => setOpenNewContentCreation(false), contentModel: contentModel }), openContentVisualisation && _jsx(ContentVisualisation, { isContentVisualisationOpen: openContentVisualisation, content: contentModel, onClose: () => { setOpenContentVisualisation(false); setContentModel(undefined); } }), _jsxs("div", Object.assign({ className: "flex flex-col items-stretch", style: { width: '920px', alignSelf: 'center' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between", style: { margin: '32px 0px' } }, { children: [role === EUserRole.ADMINISTRATOR &&
                                    _jsxs("button", Object.assign({ className: "flex flex-row items-center justify-center", style: {
                                            backgroundImage: "url(./assets/UI/Button-userfront.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundClip: "content-box",
                                            backgroundSize: "100% 100%",
                                            width: "165px",
                                        }, onClick: () => { setOpenNewContentCreation(true); setIsToModify(false); } }, { children: [_jsx(PlusCircleIcon, {}), _jsx("div", Object.assign({ className: "flex self-center", style: { marginLeft: "9px", color: 'white', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'bold' } }, { children: "NOUVEAU CONTENU" }))] })), _jsx("div", Object.assign({ className: "" }, { children: _jsx(SearchBar, { placeholder: "Rechercher un contenu p\u00E9dagogique", onInputChanged: handleSearchChanged }) }))] })), _jsx("div", { className: "w-full", style: {
                                backgroundImage: "url(./assets/UI/Ligne-crayon.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundClip: "content-box",
                                backgroundSize: "contain",
                                height: "4px"
                            } }), filteredContents.map((item, index) => {
                            return _jsx(ContentItem, { role: role, setOpenContent: setOpenContentVisualisation, setOpenContentModification: setOpenNewContentCreation, setIsToModify: () => { setIsToModify(true); }, constentInfos: item, setOpenToaster: setToasterOpen, setContent: setContentModel }, "contentItem" + index);
                        })] }))] })) })));
}
export default ContentsPage;
