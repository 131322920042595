var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
function getSessionsList() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            let response = yield axios.get(`${process.env.REACT_APP_API_URL}/sessions`);
            // alert(JSON.stringify(response.data));
            console.log(response.data);
            return response.data;
        }
        catch (error) {
            console.error(Object.keys(error));
        }
    });
}
function createSession(infos) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield axios.post(`${process.env.REACT_APP_API_URL}/sessions`, infos); // TODO check errors
        }
        catch (error) {
            console.error(Object.keys(error), error.message);
        }
    });
}
function deleteSession(sessionInfos) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield axios.delete(`${process.env.REACT_APP_API_URL}/sessions?sessionId=${encodeURIComponent(sessionInfos.Id)}`); // TODO check errors
        }
        catch (error) {
            console.error(Object.keys(error), error.message);
        }
    });
}
export { getSessionsList, createSession, deleteSession };
