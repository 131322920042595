var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import MenuBar from "../components/MenuBar";
import ArrowRightCircleIcon from "../components/icons/ArrowRightCircle";
import GearIcon from "../components/icons/GearIcon";
import TrashIcon from "../components/icons/TrashIcon";
import { useNavigate } from "react-router-dom";
import useAuth from "../tools/useAuth";
import GeneralToaster from "../components/Toasters/GeneralToaster";
import isNullOrUndefined from "../tools/isNullOrUndefined";
import axios from "axios";
import SearchBar from "../components/SearchBar";
function DeckItem(props) {
    const navigate = useNavigate();
    function navigateToDeckContent() {
        navigate("/DeckModificationsPage", { state: { deckInfos: props.deckInfos } });
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3", style: { borderBottom: '1px solid #A1AABC7F', padding: '20px 17px' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col flex-grow" }, { children: [_jsx("div", Object.assign({ className: "flex flex-row justify-between" }, { children: _jsx("div", Object.assign({ className: "text-dilemmeDeepPurpple font-roboto font-medium", style: { fontSize: '18px', marginRight: '10px' } }, { children: props.deckInfos.name })) })), _jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsxs("div", Object.assign({ className: "font-roboto", style: { fontSize: '14px', fontWeight: 'normal', marginRight: '10px' } }, { children: [props.deckInfos.cardsCount, " cartes"] })), _jsx("div", Object.assign({ className: "font-roboto", style: { fontSize: '14px', fontWeight: 'bold', marginRight: '10px' } }, { children: props.deckInfos.animatorName }))] }))] })), !props.deckInfos.isDefault &&
                _jsxs("div", Object.assign({ className: "flex gap-3 h-full" }, { children: [_jsx("button", Object.assign({ onClick: () => navigateToDeckContent(), style: { width: '30px', height: '30px', background: 'black', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx(GearIcon, {}) })), _jsx("button", Object.assign({ onClick: () => { props.setDeckToDelete(props.deckInfos); props.setToasterOpen(true); }, style: { width: '30px', height: '30px', background: 'black', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx(TrashIcon, {}) }))] }))] })));
}
function CardsPage() {
    const { username } = useAuth();
    const [decks, setDecks] = useState([]);
    const [filteredDecks, setFilteredDecks] = useState([]);
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [toasterOpen, setToasterOpen] = useState(false);
    const [deckToDelete, setDeckToDelete] = useState();
    const navigate = useNavigate();
    function handleSearchChanged(search) {
        let newList = decks.filter((item) => {
            return item.name.toLowerCase().includes(search.toLowerCase());
        });
        setFilteredDecks(newList);
    }
    function createNewDeck() {
        navigate("/DeckModificationsPage");
    }
    function getDeckList() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(`${process.env.REACT_APP_API_URL}/decks`);
            return response.data;
        });
    }
    function updateDecksList() {
        getDeckList().then((data) => {
            setDecks(data);
            setFilteredDecks(data);
        });
    }
    function deleteDeck(deckInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            yield axios.delete(`${process.env.REACT_APP_API_URL}/decks/${deckInfo.deckId}`); // TODO check errors
            updateDecksList();
        });
    }
    useEffect(() => {
        updateDecksList();
        console.log(decks);
    }, []);
    return (_jsxs("div", Object.assign({ className: "h-full w-screen bg-white flex  flex-col", style: {
            zIndex: -1,
        } }, { children: [toasterOpen && _jsx(GeneralToaster, { message: "Souhaitez-vous supprime le deck suivant ?", name: deckToDelete === null || deckToDelete === void 0 ? void 0 : deckToDelete.name, title: "supprimer le deck", buttonTextCancel: "Annuler", buttonTextValidate: "Supprimer", onValidate: () => { if (!isNullOrUndefined(deckToDelete)) {
                    deleteDeck(deckToDelete);
                    setToasterOpen(false);
                } }, onCancel: () => { setToasterOpen(false); }, onClose: () => { setToasterOpen(false); } }), _jsx(MenuBar, { setOpenResetPassword: setOpenResetPassword, selectedTab: 1 }), _jsxs("div", Object.assign({ className: "flex flex-col justify-center relative items-stretch", style: { width: '920px', minWidth: "920px", alignSelf: 'center' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between", style: { margin: '32px 0px' } }, { children: [_jsxs("button", Object.assign({ className: "flex flex-row items-center self-start justify-center", style: {
                                    backgroundImage: "url(./assets/UI/Button-userfront.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundClip: "content-box",
                                    backgroundSize: "contain",
                                    width: "200px",
                                    height: "50px",
                                }, onClick: createNewDeck }, { children: [_jsx(ArrowRightCircleIcon, {}), _jsx("div", Object.assign({ className: "flex justify-center", style: { width: '144px', color: 'white', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'bold', textTransform: "uppercase" } }, { children: "nouveau deck" }))] })), _jsx("div", Object.assign({ className: "" }, { children: _jsx(SearchBar, { placeholder: "Rechercher un nom de deck", onInputChanged: handleSearchChanged }) }))] })), _jsx("div", { className: "w-full", style: {
                            backgroundImage: "url(./assets/UI/Ligne-crayon.png)",
                            backgroundRepeat: "no-repeat",
                            backgroundClip: "content-box",
                            backgroundSize: "contain",
                            height: "4px"
                        } }), filteredDecks.map((item, index) => _jsx("div", Object.assign({ style: { background: (index % 2 === 0 ? 'transparent' : '#A1AABC33') } }, { children: _jsx(DeckItem, { deckInfos: item, index: index, setDeckToDelete: setDeckToDelete, setToasterOpen: setToasterOpen }) }), "DeckItem" + index))] }))] })));
}
export default CardsPage;
