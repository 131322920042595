import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import DownloadIcon from "./icons/DownloadIcon";
function DownloadFileButton(props) {
    const [over, setOver] = useState(false);
    let backgroundColor = '#54659F';
    function handleFileChange(event) {
        if (event.target.files) {
            props.setFile(event.target.files[0]);
        }
    }
    if (over) {
        backgroundColor = '#38487E';
    }
    else {
        backgroundColor = '#54659F';
    }
    return (_jsx("div", Object.assign({ className: "flex w-full" }, { children: _jsxs("div", Object.assign({ className: "flex gap-0 flex-col font-roboto justify-start", style: {
                fontWeight: "400",
            } }, { children: [_jsxs("label", Object.assign({ htmlFor: "hello", className: " flex items-center gap-2 justify-start", onMouseOver: () => setOver(true), onMouseOut: () => setOver(false), style: {
                        backgroundColor: backgroundColor,
                        width: "fit-content",
                        height: "30px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        borderRadius: "16px",
                        cursor: "pointer",
                        fontWeight: '700',
                        fontSize: "12px",
                        lineHeight: "normal",
                    } }, { children: [_jsx(DownloadIcon, {}), _jsx("div", Object.assign({ className: "flex text-white" }, { children: "Importer un fichier" }))] })), _jsx("input", { id: "hello", type: "file", accept: ".png, .mp4, .jpeg", style: {
                        width: "0.1px",
                        height: "0.1px",
                        opacity: "0",
                        overflow: "hidden",
                        position: "absolute",
                        zIndex: "-1",
                    }, onChange: handleFileChange })] })) })));
}
export default DownloadFileButton;
