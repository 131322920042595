"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ECategoriesCards;
(function (ECategoriesCards) {
    ECategoriesCards["ASSURANCE"] = "ASSURANCE";
    ECategoriesCards["BANQUE"] = "CONNAITRE_LA_BANQUE";
    ECategoriesCards["CREDIT"] = "CREDIT";
    ECategoriesCards["DROIT"] = "DROIT _ET_FISCALITE";
    ECategoriesCards["EPARGNE"] = "EPARGNE";
    ECategoriesCards["BUDGET"] = "GERER_SON_BUDGET";
    ECategoriesCards["JEU"] = "JEU_D_ARGENT";
    ECategoriesCards["Q_ECONOMIE"] = "QUELQUES_ ECONOMIES";
    ECategoriesCards["CHANCE"] = "CHANCE";
    ECategoriesCards["DEPENSE"] = "DEPENSE IMPREVUE";
    ECategoriesCards["DILEMME"] = "DILEMME";
    ECategoriesCards["ECONOMIE"] = "Economies";
})(ECategoriesCards || (ECategoriesCards = {}));
exports.default = ECategoriesCards;
