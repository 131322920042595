import React from "react";
import AuthContext from "../AuthContext";
const useAuth = () => {
    const context = React.useContext(AuthContext);
    console.log("useAuth context: ", context, AuthContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }
    return context;
};
export default useAuth;
