"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ESessionStatus;
(function (ESessionStatus) {
    ESessionStatus[ESessionStatus["NONE"] = -1] = "NONE";
    ESessionStatus[ESessionStatus["ON_GOING"] = 0] = "ON_GOING";
    ESessionStatus[ESessionStatus["TODAY"] = 1] = "TODAY";
    ESessionStatus[ESessionStatus["AVAILABLE"] = 2] = "AVAILABLE";
})(ESessionStatus || (ESessionStatus = {}));
exports.default = ESessionStatus;
