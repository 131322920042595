var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import "../App.css";
import "../App.tailwind.css";
import Userfront from "@userfront/core";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckPasswordCriteria, CheckPassword_Length, CheckPassword_LowerCase, CheckPassword_OneDigit, CheckPassword_SpecialCharacter, CheckPassword_UpperCase } from "../tools/PasswordCheck";
import EyeIcon from "../components/icons/EyeIcon";
import EyeClosedIcon from "../components/icons/EyeClosedIcon";
function PasswordModificationPage() {
    const refPasswordInput = useRef();
    const refHolder = useRef();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorPasswordMessage, setErrorPasswordMessage] = useState("");
    const [firstConnexion, setFirstConnexion] = useState(true);
    // Use the useLocation hook to get the current location
    const location = useLocation();
    // Create a new URLSearchParams object to parse query parameters
    const queryParams = new URLSearchParams(location.search);
    // Access and store the values of the query parameters
    const uuid = queryParams.get('uuid');
    const type = queryParams.get('type');
    const token = queryParams.get('token');
    console.log(token, 'token');
    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    function getEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            if (uuid !== null && uuid !== undefined && token !== null && token !== undefined) {
                // a mettre coté back
                const response = yield fetch(`https://api.userfront.com/v0/users/${uuid}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer uf_live_readonly_8nwmqzvn_b88f670b77ba3f73de5f7dc380393941" //secrets
                    }
                });
                console.log(response, 'response');
                const dataUser = yield response.json();
                console.log(dataUser.email, 'dataUser');
                setEmail(dataUser.email);
                console.log(email, 'email in get email');
                setFirstConnexion(false);
            }
            else if (accessToken !== undefined && accessToken !== null) {
                const accessToken = localStorage.getItem('accessToken');
                const response = yield fetch("https://api.userfront.com/v0/self", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                console.log(response, 'response');
                const dataUser = yield response.json();
                console.log(dataUser.email, 'dataUser');
                setEmail(dataUser.email);
                console.log(email, 'email in get email');
                setFirstConnexion(true);
            }
        });
    }
    function handleShowPassword() {
        setShowPassword(!showPassword);
    }
    function handleShowConfirmationPassword() {
        setShowConfirmationPassword(!showConfirmationPassword);
    }
    useEffect(() => {
        if (errorPassword) {
            refHolder.current.style.height = "579px";
        }
        else {
            refHolder.current.style.height = "539px";
        }
    }, [errorPassword]);
    useEffect(() => {
        getEmail();
        console.log(accessToken, 'accessToken');
        console.log(email, 'email');
        setErrorPassword(false);
    }, [email]);
    useEffect(() => {
        let result = CheckPasswordCriteria(password);
        console.log(`password : ${password} CheckPasswordCriteria${result} length${CheckPassword_Length(password)} lower${CheckPassword_LowerCase(password)} upper${CheckPassword_UpperCase(password)} special${CheckPassword_SpecialCharacter(password)} `);
    }, [password]);
    useEffect(() => {
        const callback = (e) => {
            if (e.key === "Enter") {
                handleCheckPassword();
            }
        };
        document.addEventListener("keydown", callback);
        return () => document.removeEventListener("keydown", callback);
    }, [email, password, confirmPassword]);
    function handleCheckPassword() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(accessToken);
            if (CheckPasswordCriteria(password)) {
                if (password === confirmPassword) {
                    if (uuid !== null && token !== null) {
                        Userfront.updatePassword({
                            password: password,
                            uuid: uuid,
                            token: token,
                        }).then((response) => { console.log(response); }).catch((err) => {
                            console.log(uuid);
                            console.log(token);
                            console.log(err);
                        });
                    }
                    else if (accessToken !== undefined && accessToken !== null) {
                        const payload = {
                            password: password,
                        };
                        const response = yield fetch("https://api.userfront.com/v0/auth/basic", {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${accessToken}`
                            },
                            body: JSON.stringify(payload)
                        });
                        console.log(response, 'response');
                        console.log(password, 'password');
                        console.log(email, 'email');
                        Userfront.login({
                            method: "password",
                            email: email,
                            password: password,
                            redirect: "/SessionsPage",
                        }).then((response) => {
                            if (rememberMe) {
                                localStorage.setItem('email', email);
                                localStorage.setItem('password', password);
                            }
                            else {
                                localStorage.removeItem('email');
                                localStorage.removeItem('password');
                            }
                            console.log(response);
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                }
                else {
                    console.log("passwords don't match");
                    setErrorPasswordMessage("Les mots de passe ne correspondent pas");
                    setErrorPassword(true);
                }
            }
            else {
                setErrorPasswordMessage("Le mot de passe ne remplit pas toutes les conditions");
                setErrorPassword(true);
                console.log("password doesn't match criteria");
            }
        });
    }
    return (_jsxs("div", Object.assign({ className: " w-screen h-screen flex absolute justify-center", style: {
            opacity: "1",
        } }, { children: [_jsx("div", { className: "flex absolute bg-dilemmeDeepPurpple w-full h-full ", style: {
                    opacity: "0.8",
                } }), _jsx("div", Object.assign({ className: "flex absolute w-full h-full justify-center" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col relative items-center bg-white justify-start", ref: refHolder, style: {
                        width: "428px",
                        // same
                        top: "7.45%",
                        padding: "32px",
                        gap: "24px",
                        border: "2px solid #000000",
                        // opacity: "1",
                    } }, { children: [_jsx("div", Object.assign({ className: "flex relative text-dilemmeDeepPurpple font-roboto justify-center items-center text-center", style: {
                                width: `100%`,
                                fontSize: "18px",
                                lineHeight: "normal",
                                fontWeight: "700",
                                textTransform: "uppercase",
                            } }, { children: firstConnexion ? "première connexion" : "modification du mot de passe" })), errorPassword && _jsx("div", Object.assign({ className: "flex relative text-teamRed font-roboto justify-center items-center text-start", style: {
                                width: "100%",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontStyle: "normal",
                            } }, { children: errorPasswordMessage })), _jsx("div", Object.assign({ className: "flex relative font-roboto text-black text-center justify-center", style: {
                                width: "100%",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontStyle: "normal",
                            } }, { children: email })), _jsxs("div", Object.assign({ className: "flex-col flex relative font-roboto text-black", style: {
                                width: "100%",
                                // height: "18.50%",    /* 65px */
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "16px",
                                fontStyle: "normal",
                                listStyle: "disc"
                            } }, { children: ["Veuillez saisir votre mot de passe. Il doit contenir au moins :", _jsx("li", Object.assign({ style: { color: `${CheckPassword_Length(password) ? "green" : "red"}` } }, { children: "8 caract\u00E8res" })), _jsx("li", Object.assign({ style: CheckPassword_UpperCase(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 majuscule" })), _jsx("li", Object.assign({ style: CheckPassword_LowerCase(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 minuscule" })), _jsx("li", Object.assign({ style: CheckPassword_OneDigit(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 chiffre" })), _jsx("li", Object.assign({ style: CheckPassword_SpecialCharacter(password) ? { color: 'green' } : { color: 'red' } }, { children: "1 caract\u00E8re sp\u00E9cial" }))] })), _jsxs("div", Object.assign({ className: "flex flex-col relative", style: {
                                width: "100%",
                                gap: "16px"
                            } }, { children: [_jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                        width: "100%",
                                        gap: "8px",
                                    } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                                width: "100%",
                                                fontSize: "14px",
                                                lineHeight: "normal",
                                                fontWeight: "600",
                                            } }, { children: "Mot de Passe" })), _jsxs("div", Object.assign({ className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                                width: "100%",
                                                height: "auto",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                paddingTop: "8px",
                                                paddingBottom: "8px",
                                                border: "1px solid #A1AABC",
                                                borderRadius: "4px",
                                            } }, { children: [_jsx("input", { ref: refPasswordInput, id: "password", name: "Password", type: showPassword ? "text" : "password", autoComplete: "current-password", required: true, pattern: "(?=^.{8,}$)(?=.*\\W+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$                                ", value: password, onChange: value => setPassword(value.target.value), 
                                                    //use pattern for password when creating the account
                                                    className: "flex absolute font-roboto text-black bg-brokenWhite", style: {
                                                        left: "0%",
                                                        top: "0%",
                                                        paddingLeft: "10px",
                                                        paddingRight: "9.46%",
                                                        paddingTop: "8px",
                                                        paddingBottom: "8px",
                                                        height: "100%",
                                                        width: "100%",
                                                        borderRadius: "4px",
                                                    } }), _jsx("button", Object.assign({ onClick: handleShowPassword, className: "flex relative", style: {
                                                        height: "100%",
                                                        width: "7.08%",
                                                        left: "94%",
                                                    } }, { children: showPassword ? _jsx(EyeIcon, { color: "black" }) : _jsx(EyeClosedIcon, { color: "black" }) }))] }))] })), _jsxs("div", Object.assign({ className: "flex-col flex relative", style: {
                                        width: "100%",
                                        gap: "8px",
                                    } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                                width: "100%",
                                                fontSize: "14px",
                                                lineHeight: "normal",
                                                fontWeight: "600",
                                            } }, { children: "Confirmation du mot de passe" })), _jsxs("div", Object.assign({ className: "flex relative font-roboto text-black bg-brokenWhite", style: {
                                                width: "100%",
                                                height: "auto",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                paddingTop: "8px",
                                                paddingBottom: "8px",
                                                border: "1px solid #A1AABC",
                                                borderRadius: "4px",
                                            } }, { children: [_jsx("input", { id: "passwordComfirmation", name: "passwordComfirmation", type: showConfirmationPassword ? "text" : "password", autoComplete: "current-password", required: true, value: confirmPassword, onChange: value => setConfirmPassword(value.target.value), 
                                                    //use pattern for password when creating the account
                                                    className: "flex absolute font-roboto text-black bg-brokenWhite", style: {
                                                        left: "0%",
                                                        top: "0%",
                                                        paddingLeft: "10px",
                                                        paddingRight: "9.46%",
                                                        paddingTop: "8px",
                                                        paddingBottom: "8px",
                                                        height: "100%",
                                                        width: "100%",
                                                        borderRadius: "4px",
                                                    } }), _jsx("button", Object.assign({ onClick: handleShowConfirmationPassword, className: "flex relative", style: {
                                                        height: "100%",
                                                        width: "7.08%",
                                                        left: "94%",
                                                    } }, { children: showConfirmationPassword ? _jsx(EyeIcon, { color: "black" }) : _jsx(EyeClosedIcon, { color: "black" }) }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-row justify-start self-start relative", style: {
                                        height: "16px",
                                        width: "41.51%",
                                    } }, { children: [_jsx("input", { type: "checkbox", id: "remember me", name: "remember me", value: "remember me", checked: rememberMe, onChange: value => setRememberMe(value.target.checked), className: "flex relative", style: {
                                                height: "100%",
                                                width: "11.19%",
                                                marginRight: "8px",
                                            } }), _jsx("label", Object.assign({ className: "flex relative font-roboto text-black", style: {
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                fontStyle: "normal",
                                                lineHeight: "normal",
                                            } }, { children: "Se souvenir de moi" }))] }))] })), _jsx("button", Object.assign({ onClick: handleCheckPassword, className: "flex relative bg-dilemmeDeepPurpple text-white font-roboto justify-center text-center items-center hover:bg-white hover:text-dilemmeDeepPurpple hover:border-dilemmeDeepPurpple", style: {
                                width: "100%",
                                height: "50px",
                                fontSize: "14px",
                                fontWeight: "900",
                                lineHeight: "14px",
                                textTransform: "uppercase",
                                fontStyle: "normal",
                                border: "2px solid #000000",
                            } }, { children: "SE CONNECTER" }))] })) }))] })));
}
export default PasswordModificationPage;
