var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../App.css";
import "../App.tailwind.css";
import Userfront from "@userfront/core";
import MenuTabButton from "./MenuTabButton";
import { useNavigate } from "react-router-dom";
import useAuth from "../tools/useAuth";
import EUserRole from "cresus-common/dist/dto/EUserRole";
function MenuBar(props) {
    const { username, role, onAskPasswordChange } = useAuth();
    const navigate = useNavigate();
    function deconnexionHandler() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Deconnecting user " + Userfront.user);
            yield Userfront.logout({
                redirect: "/ConnectionPage"
            }).then(respone => {
                console.log(respone);
            }).catch((err) => {
                console.log(err);
            });
        });
    }
    function handleTabClick(path) {
        navigate(path);
    }
    return (_jsx("div", Object.assign({ className: "w-screen bg-white flex", style: { zIndex: "1" } }, { children: _jsxs("div", Object.assign({ className: "flex justify-between relative", style: {
                width: "100%",
                height: "80px",
                minHeight: "80px",
                minWidth: "1024px",
                background: "linear-gradient(90deg,#6F82C0, #7258A5)",
                overflow: "hidden",
            } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row", style: { margin: "0px 20px" } }, { children: [_jsx("div", { className: "flex relative", style: {
                                height: "40px",
                                width: "111px",
                                top: "25%",
                                marginLeft: "2%",
                                backgroundImage: "url(./assets/UI/logo_dilemme.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundClip: "content-box",
                                backgroundPosition: "center",
                            } }), _jsx("div", { className: "flex relative", style: {
                                height: "80px",
                                top: "10%",
                                overflow: "hidden",
                                aspectRatio: "1/1",
                                backgroundImage: "url(./assets/UI/cygogne_dilemme.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundClip: "content-box",
                                backgroundPosition: "center",
                            } })] })), _jsxs("div", Object.assign({ className: "flex items-end justify-center flex-row relative w-auto", style: {
                        gap: "5px",
                        bottom: "-2px",
                        width: "1",
                    } }, { children: [_jsx(MenuTabButton, { text: "Sessions", onClick: handleTabClick, path: "/SessionsPage", active: props.selectedTab === 0 }), _jsx(MenuTabButton, { text: "Cartes", onClick: handleTabClick, path: "/CardsPage", active: props.selectedTab === 1 }), role === EUserRole.ADMINISTRATOR && _jsx(MenuTabButton, { text: "Utilisateurs", onClick: handleTabClick, path: "/UsersPage", active: props.selectedTab === 2 }), _jsx(MenuTabButton, { text: "Contenus", onClick: handleTabClick, path: "/ContentsPage", active: props.selectedTab === 3 })] })), _jsxs("div", Object.assign({ className: "flex flex-col", style: {
                        height: "50%",
                        right: "0%",
                        width: "191px",
                        // minWidth: "181px",
                        top: "25%",
                        margin: "20px"
                    } }, { children: [_jsxs("div", Object.assign({ className: "flex relative justify-end items-end", style: {
                                width: "100%",
                                height: "30%",
                            } }, { children: [_jsx("button", Object.assign({ className: "flex relative font-roboto justify-start text-start items-center text-white", onClick: onAskPasswordChange, style: {
                                        width: "42.33%",
                                        height: "100%",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                    } }, { children: "Mot de Passe" })), _jsx("div", { className: "relative flex", style: {
                                        width: "5.52%",
                                        height: "100%",
                                        backgroundImage: "url(./assets/UI/line_middle_backoffice.png)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundClip: "content-box",
                                        backgroundPosition: "center",
                                    } }), _jsx("button", Object.assign({ className: "flex relative font-roboto justify-end items-center text-start text-white", onClick: deconnexionHandler, style: {
                                        width: "39.23%",
                                        height: "100%",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "normal",
                                    } }, { children: "D\u00E9connexion" })), _jsx("div", Object.assign({ className: "flex relative", style: {
                                        height: "100%",
                                        width: "9.92%",
                                    } }, { children: _jsx("svg", Object.assign({ width: "100%", height: "100%", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M6.66667 0H5.33333V6.66667H6.66667V0ZM9.88667 1.44667L8.94 2.39333C9.99333 3.24 10.6667 4.54 10.6667 6C10.6667 8.58 8.58 10.6667 6 10.6667C3.42 10.6667 1.33333 8.58 1.33333 6C1.33333 4.54 2.00667 3.24 3.05333 2.38667L2.11333 1.44667C0.82 2.54667 0 4.17333 0 6C0 9.31333 2.68667 12 6 12C9.31333 12 12 9.31333 12 6C12 4.17333 11.18 2.54667 9.88667 1.44667Z", fill: "#E0E0F7" }) })) }))] })), _jsx("div", { className: "flex relative", style: {
                                width: "100%",
                                height: "22.5%",
                                backgroundImage: "url(./assets/UI/line_backoffice_infos.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundClip: "content-box",
                                backgroundPosition: "center",
                            } }), _jsxs("div", Object.assign({ className: "flex relative justify-end", style: {
                                width: "100%",
                                height: "47.5%",
                            } }, { children: [_jsx("div", Object.assign({ className: "flex relative font-roboto justify-end self-center text-start text-white overflow-hidden", style: {
                                        width: "90.08%",
                                        height: "100%",
                                        marginRight: "2.76%",
                                        fontSize: "16px",
                                        fontStyle: "normal",
                                        fontWeight: "700",
                                        lineHeight: "normal",
                                    } }, { children: username })), _jsx("div", Object.assign({ className: "flex relative self-center", style: {
                                        width: "9.92%",
                                        height: "63.16%",
                                    } }, { children: _jsx("svg", Object.assign({ width: "100%", height: "100%", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M6 0C9.312 0 12 2.688 12 6C12 9.312 9.312 12 6 12C2.688 12 0 9.312 0 6C0 2.688 2.688 0 6 0ZM6 1.8C5.004 1.8 4.2 2.604 4.2 3.6C4.2 4.596 5.004 5.4 6 5.4C6.996 5.4 7.8 4.596 7.8 3.6C7.8 2.604 6.996 1.8 6 1.8ZM6 10.32C7.5 10.32 8.826 9.552 9.6 8.388C9.582 7.194 7.2 6.54 6 6.54C4.806 6.54 2.418 7.194 2.4 8.388C3.174 9.552 4.5 10.32 6 10.32Z", fill: "white" }) })) }))] }))] })), _jsx("div", { className: "w-full absolute", style: {
                        bottom: "-2px",
                        backgroundImage: "url(./assets/UI/Ligne-crayon.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundClip: "content-box",
                        backgroundSize: "contain",
                        height: "4px"
                    } })] })) })));
}
export default MenuBar;
