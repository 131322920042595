import ECategoriesCards from "cresus-common/dist/dto/ECategoriesCards";
import EColorTag from "cresus-common/dist/dto/EColorsTag";
function switchForColorToCategorie(categoriesCards) {
    switch (categoriesCards) {
        case ECategoriesCards.ASSURANCE:
            return EColorTag.PURPLE;
        case ECategoriesCards.BANQUE:
            return EColorTag.DARK_BLUE;
        case ECategoriesCards.CREDIT:
            return EColorTag.LIGHT_BLUE;
        case ECategoriesCards.DROIT:
            return EColorTag.GREEN_BLUE;
        case ECategoriesCards.EPARGNE:
            return EColorTag.GREEN;
        case ECategoriesCards.BUDGET:
            return EColorTag.ORANGE;
        case ECategoriesCards.JEU:
            return EColorTag.PINK;
        case ECategoriesCards.Q_ECONOMIE:
            return EColorTag.PINK_PURPLE;
        case ECategoriesCards.CHANCE:
            return EColorTag.PURPLE;
        case ECategoriesCards.DEPENSE:
            return EColorTag.DARK_BLUE;
        case ECategoriesCards.DILEMME:
            return EColorTag.LIGHT_BLUE;
        case ECategoriesCards.ECONOMIE:
            return EColorTag.GREEN_BLUE;
    }
}
export default switchForColorToCategorie;
