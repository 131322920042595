var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import "../App.css";
import "../App.tailwind.css";
import axios from "axios";
import ESessionStatus from "cresus-common/dist/dto/ESessionStatus";
import EUserRole from "cresus-common/dist/dto/EUserRole";
import RoundedFunctionalButton from "../components/FunctionalButton";
import MenuBar from "../components/MenuBar";
import SearchBar from "../components/SearchBar";
import SessionStatusTag from "../components/SessionStatusTag";
import GeneralToaster from "../components/Toasters/GeneralToaster";
import AccessIcon from "../components/icons/AccessIcon";
import GearIcon from "../components/icons/GearIcon";
import PlusCircleIcon from "../components/icons/PlusCircle";
import TrashIcon from "../components/icons/TrashIcon";
import { getSessionsList } from "../services/SessionsRequests";
import useAuth from "../tools/useAuth";
import CreateSessionPage from "./CreateSessionPage";
import ModifySessionPage from "./ModifySessionPage";
import { formatTimeFromDate } from "cresus-common/dist/utils/DateUtils";
const deleteDateFormatOptions = {
    dateStyle: "long"
};
function SessionItem(props) {
    const { role } = useAuth();
    function navigateToSession() {
        window.open(`${process.env.REACT_APP_FRONTEND_URL}/?sessionId=${props.sessionInfos.Id}&isAnimator=true`, "_blank");
    }
    const animatorStructure = (props.sessionInfos.AnimatorStructure) ? ` - ${props.sessionInfos.AnimatorStructure}` : "";
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3", style: { borderBottom: '1px solid #A1AABC7F', padding: '20px 17px' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col flex-grow" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsx("div", Object.assign({ className: "text-dilemmeDeepPurpple font-roboto font-medium", style: { fontSize: '18px', marginRight: '10px' } }, { children: props.sessionInfos.Name })), _jsx(SessionStatusTag, { type: props.sessionInfos.Status })] })), _jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [_jsx("div", Object.assign({ className: "font-roboto", style: { fontSize: '14px', fontWeight: 'normal', marginRight: '10px' } }, { children: `${new Date(props.sessionInfos.Date).toLocaleDateString("fr-FR", deleteDateFormatOptions)} - ${formatTimeFromDate(new Date(props.sessionInfos.Date))}` })), role === EUserRole.ADMINISTRATOR &&
                                _jsx("div", Object.assign({ style: { fontWeight: 'bold', fontSize: '14px' } }, { children: `${props.sessionInfos.AnimatorName} ${animatorStructure}` }))] }))] })), _jsx("button", Object.assign({ onClick: () => { props.onModify(props.sessionInfos); }, style: { width: '30px', height: '30px', background: 'black', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx(GearIcon, {}) })), _jsx("button", Object.assign({ onClick: () => { props.onDelete(props.sessionInfos); }, style: { width: '30px', height: '30px', background: 'black', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' } }, { children: _jsx(TrashIcon, {}) })), _jsx(RoundedFunctionalButton, { fontSize: "12px", text: "Acc\u00E9der \u00E0 la session", colorMode: 0, icon: AccessIcon, showLeftIcon: true, onClick: navigateToSession })] })));
}
function SessionsPage() {
    var _a, _b, _c;
    const [sessions, setSessions] = useState([]);
    const [filteredSessions, setFilteredSessions] = useState([]);
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [newSessionPageVisibility, setNewSessionPageVisibility] = useState(false);
    const [modifySessionPageVisibility, setModifySessionPageVisibility] = useState(false);
    const [deleteSessionPageVisibility, setDeleteSessionPageVisibility] = useState(false);
    const defaultSessionInfos = {
        Date: new Date(),
        Language: "",
        Comment: "",
        Id: 0,
        Name: "",
        Status: ESessionStatus.NONE,
        AnimatorName: "",
        AnimatorEmail: "",
        AnimatorStructure: "",
        DeckId: 0,
        AnimatorId: 0,
        Code: "1234",
    };
    const refModifySession = useRef(defaultSessionInfos);
    function updateSessionsList() {
        getSessionsList().then((data) => {
            const sortedData = data.sort((a, b) => {
                return new Date(a.Date).getTime() - new Date(b.Date).getTime();
            });
            setSessions(sortedData);
            setFilteredSessions(sortedData);
        });
    }
    useEffect(() => {
        updateSessionsList();
    }, []);
    function handleSearchChanged(search) {
        let newList = sessions.filter((item) => {
            return item.Name.toLowerCase().includes(search.toLowerCase())
                || item.AnimatorName.toLowerCase().includes(search.toLowerCase())
                || (item.AnimatorStructure && item.AnimatorStructure.toLowerCase().includes(search.toLowerCase()));
        });
        setFilteredSessions(newList);
    }
    function onClickNewSession() {
        setNewSessionPageVisibility(true);
    }
    function onClickModifySession(sessionInfos) {
        refModifySession.current = sessionInfos;
        setModifySessionPageVisibility(true);
    }
    function modifySession_() {
        return __awaiter(this, void 0, void 0, function* () {
            setModifySessionPageVisibility(false);
            updateSessionsList();
        });
    }
    function createSession_(infos) {
        setNewSessionPageVisibility(false);
        updateSessionsList();
    }
    function deleteSession_(sessionInfos) {
        return __awaiter(this, void 0, void 0, function* () {
            yield axios.delete(`${process.env.REACT_APP_API_URL}/sessions/${sessionInfos.Id}`); // TODO check errors
            updateSessionsList();
            setDeleteSessionPageVisibility(false);
        });
    }
    function onClickDeleteSession(sessionInfos) {
        refModifySession.current = sessionInfos;
        setDeleteSessionPageVisibility(true);
    }
    function onCancelSuppression() {
        setDeleteSessionPageVisibility(false);
    }
    return (_jsxs("div", Object.assign({ className: "h-full w-screen bg-white flex flex-col right-0", style: {
            zIndex: -1,
        } }, { children: [_jsx("div", Object.assign({ style: { visibility: newSessionPageVisibility ? 'visible' : 'hidden' } }, { children: _jsx(CreateSessionPage, { onClose: () => { setNewSessionPageVisibility(false); }, onValidate: createSession_ }, "newSession" + new Date().toString()) })), _jsx("div", Object.assign({ style: { visibility: modifySessionPageVisibility ? 'visible' : 'hidden' } }, { children: _jsx(ModifySessionPage, { sessionInfos: refModifySession.current, onClose: () => { setModifySessionPageVisibility(false); }, onValidate: modifySession_ }, "modifySession" + new Date().toString()) })), _jsx("div", Object.assign({ style: { visibility: deleteSessionPageVisibility ? 'visible' : 'hidden' } }, { children: _jsx(GeneralToaster, Object.assign({ message: "Souhaitez vous supprimer la session suivante ?", title: "Supprimer la session", onValidate: () => deleteSession_(refModifySession.current), onClose: onCancelSuppression, onCancel: onCancelSuppression, buttonTextCancel: "annuler", buttonTextValidate: "supprimer" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("div", Object.assign({ className: "text-center", style: { fontWeight: "bold" } }, { children: (_a = refModifySession.current) === null || _a === void 0 ? void 0 : _a.Name })), _jsxs("div", Object.assign({ className: "flex flex-row" }, { children: [new Date((_b = refModifySession.current) === null || _b === void 0 ? void 0 : _b.Date).toLocaleDateString("fr-FR", deleteDateFormatOptions), _jsx("div", Object.assign({ style: { whiteSpace: "pre" } }, { children: " - " })), (_c = refModifySession.current) === null || _c === void 0 ? void 0 : _c.AnimatorName] }))] })) })) })), _jsxs("div", Object.assign({ className: "h-screen w-screen bg-white  absolute flex flex-col", style: {
                    zIndex: -1,
                } }, { children: [_jsx(MenuBar, { setOpenResetPassword: setOpenResetPassword, selectedTab: 0 }), _jsxs("div", Object.assign({ className: "flex flex-col items-stretch", style: { width: '920px', alignSelf: 'center' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between", style: { margin: '32px 0px' } }, { children: [_jsxs("button", Object.assign({ className: "flex flex-row items-center justify-center", style: {
                                            backgroundImage: "url(./assets/UI/Button-userfront.png)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundClip: "content-box",
                                            backgroundSize: "100% 100%",
                                            width: "165px",
                                        }, onClick: onClickNewSession }, { children: [_jsx(PlusCircleIcon, {}), _jsx("div", Object.assign({ className: "flex self-center", style: { marginLeft: "9px", color: 'white', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'bold' } }, { children: "NOUVELLE SESSION" }))] })), _jsx("div", Object.assign({ className: "" }, { children: _jsx(SearchBar, { placeholder: "Rechercher un nom, un animateur ou une structure", onInputChanged: handleSearchChanged }) }))] })), _jsx("div", { className: "w-full", style: {
                                    backgroundImage: "url(./assets/UI/Ligne-crayon.png)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundClip: "content-box",
                                    backgroundSize: "contain",
                                    height: "4px"
                                } }), filteredSessions.map((item, index) => _jsx("div", Object.assign({ style: { background: (index % 2 === 0 ? 'transparent' : '#A1AABC33') } }, { children: _jsx(SessionItem, { sessionInfos: item, onDelete: onClickDeleteSession, onModify: onClickModifySession }) }), "SessionItem" + index))] }))] }))] })));
}
export default SessionsPage;
