var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETypeContent from "cresus-common/dist/dto/ETypeContent";
import CrossIcon from "./icons/CrossIcon";
import { useEffect, useState } from "react";
import FunctionalButton from "./FunctionalButton";
import isNullOrUndefined from "../tools/isNullOrUndefined";
import DownloadFileButton from "./DownloadFileButton";
import axios from "axios";
import GeneralToaster from "./Toasters/GeneralToaster";
function ContentCreationComponent(props) {
    const [nameContent, setNameContent] = useState();
    const [isCreateButtonActive, setIsCreateButtonActive] = useState(false);
    const [file, setFile] = useState();
    const [contentDTO, setContentDTO] = useState({
        name: "",
        filename: "",
        size: "",
        type: ETypeContent.IMAGE,
    });
    const [textTitle, setTextTitle] = useState("Nouveau contenu");
    const [textButton, setTextButton] = useState("Créer le contenu"); // mettre dans des const
    const [fileSize, setFileSize] = useState("");
    const [isToModify, setIsToModify] = useState(props.isToModify);
    const [isToasterOpen, setIsToasterOpen] = useState(false);
    const [warningName, setWarningName] = useState(false);
    function createContent(content) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append("content", JSON.stringify(content.content));
            formData.append("file", file);
            try {
                yield axios({
                    method: "post",
                    url: `${process.env.REACT_APP_API_URL}/contents`,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });
                props.setShouldUpdateContentList(true);
                props.onClose();
            }
            catch (er) {
                console.log(er);
                const error = er;
                if (error !== undefined) {
                    if (error.response !== undefined) {
                        console.log(error.response.data.error);
                        if (error.response.data.error === "name already exist")
                            setIsToasterOpen(true);
                    }
                }
            }
        });
    }
    function deleteContent(content) {
        return __awaiter(this, void 0, void 0, function* () {
            yield axios.delete(`${process.env.REACT_APP_API_URL}/contents?fileName=${encodeURIComponent(content.filename)}`); // TODO check errors
        });
    }
    function handleUploadFinish() {
        if (!isNullOrUndefined(file)) {
            const buffContentDTO = {
                name: nameContent,
                filename: file.name,
                size: `${fileSize}`,
                type: file.type === "video/mp4" ? ETypeContent.VIDEO : ETypeContent.IMAGE,
            };
            const contentToExchangebuff = {
                content: buffContentDTO,
                file: file,
            };
            if (!isNullOrUndefined(props.contentModel)) {
                console.log("delete content");
                deleteContent(props.contentModel);
            }
            createContent(contentToExchangebuff);
        }
        else
            console.log("no file");
    }
    function handleUpload() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (file && file.size > 0) {
                const formData = new FormData();
                const buffContentDTO = {
                    name: nameContent,
                    filename: file.name,
                    size: `${file.size}`,
                    type: file.type === "video/mp4" ? ETypeContent.VIDEO : ETypeContent.IMAGE,
                };
                if (((_a = file.name) === null || _a === void 0 ? void 0 : _a.split('.')) && ((_b = file.name) === null || _b === void 0 ? void 0 : _b.split('.').length) > 2) {
                    setWarningName(true);
                    setFile(undefined);
                    return;
                }
                else {
                    formData.append("file", file);
                    setContentDTO(buffContentDTO);
                    setFileSize(toGetFileSize(parseInt(buffContentDTO.size)));
                    setWarningName(false);
                }
            }
        });
    }
    function onDelete() {
        const buffContentDTO = {
            name: "",
            filename: "",
            size: "",
            type: ETypeContent.IMAGE,
        };
        setIsToModify(false);
        setContentDTO(buffContentDTO);
        setFile(undefined);
    }
    function chooseIsToModify() {
        if (isToModify) {
            setTextTitle("Informations du contenu");
            setTextButton("Enregistrer les modifications");
            const content = {
                name: props.contentModel.name,
                filename: props.contentModel.filename,
                size: props.contentModel.size,
                type: props.contentModel.type,
            };
            setNameContent(content.name);
            setContentDTO(content);
            setFileSize(toGetFileSize(parseInt(content.size)));
        }
    }
    function toGetFileSize(size) {
        if (size < 1000)
            return `${Math.floor(size)} octets`;
        else if (size < 1000000)
            return `${Math.floor(size / 1000)}Ko`;
        else
            return `${Math.floor(size / 1000000)}Mo`;
    }
    useEffect(() => {
        console.log(props.contentModel, 'props.contentModel');
    }, [props.contentModel]);
    useEffect(() => {
        if (isToModify)
            chooseIsToModify();
    }, []);
    useEffect(() => {
        handleUpload();
    }, [file]);
    useEffect(() => {
        if ((nameContent !== "" && !isNullOrUndefined(nameContent) && file) || (isToModify)) {
            setIsCreateButtonActive(true);
        }
        else {
            setIsCreateButtonActive(false);
        }
    }, [nameContent, file,]);
    return (_jsxs("div", Object.assign({ className: " w-screen h-screen flex fixed justify-center", style: {
            opacity: "1",
            zIndex: '10',
        } }, { children: [_jsx("div", { className: "flex absolute bg-dilemmeDeepPurpple w-full h-full ", style: {
                    opacity: "0.5",
                } }), isToasterOpen && _jsx("div", Object.assign({ className: "flex fixed h-full w-full z-10" }, { children: _jsx(GeneralToaster, { title: "Nom \u00E9xistant", message: "Ce nom de contenu p\u00E9dagogique existe d\u00E9j\u00E0", buttonTextValidate: "Ok", buttonTextCancel: "Annuler", onClose: () => { setIsToasterOpen(false); }, onValidate: () => { setIsToasterOpen(false); }, onCancel: () => { setIsToasterOpen(false); } }) })), _jsxs("div", Object.assign({ className: "flex flex-col absolute bg-white justify-center self-center items-center", style: {
                    width: "500px",
                    padding: "32px",
                    gap: "24px",
                } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-center" }, { children: [_jsx("div", Object.assign({ className: "font-roboto text-center flex w-full justify-center text-dilemmeDeepPurpple", style: {
                                    fontSize: "18px",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                    textTransform: "uppercase",
                                    fontStyle: "normal",
                                } }, { children: textTitle })), _jsx("button", Object.assign({ className: "flex justify-end items-end self-end", onClick: props.onClose }, { children: _jsx(CrossIcon, {}) }))] })), _jsxs("div", Object.assign({ className: "flex w-full justify-start gap-2 flex-col font-roboto", style: {
                            fontSize: "14px",
                            fontWeight: "600",
                            fontStyle: "normal",
                            lineHeight: "normal",
                        } }, { children: [_jsx("div", { children: "Titre du contenu" }), _jsx("input", { className: "flex relative w-full ", value: nameContent, onChange: value => setNameContent(value.target.value), style: {
                                    backgroundColor: "#F5F5FA",
                                    paddingTop: "8px",
                                    paddingBottom: "8px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    borderRadius: "4px",
                                    border: "1px solid #E5E5E5",
                                } }), warningName &&
                                _jsx("div", Object.assign({ className: "flex w-full text-red-500" }, { children: "veuillez ne pas utiliser de \".\" dans le nom du fichier hormis pour son type : .png, .jpeg, .mp4" }))] })), _jsxs("div", Object.assign({ className: "flex w-full justify-start gap-2 flex-col font-roboto", style: {
                            fontSize: "14px",
                            fontWeight: "400",
                            fontStyle: "normal",
                            lineHeight: "normal",
                        } }, { children: [_jsx("div", Object.assign({ style: {
                                    fontWeight: "600",
                                } }, { children: "Contenu" })), isNullOrUndefined(file) && !isToModify &&
                                _jsxs("div", Object.assign({ className: "gap-2 flex flex-col" }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: "flex w-full" }, { children: "Format accept\u00E9 : .mp4, .png, .jpeg" })), _jsx("div", Object.assign({ className: "flex w-full" }, { children: "Taille maximale : XX Mo" }))] }), _jsx(DownloadFileButton, { setFile: setFile })] })), isNullOrUndefined(file) && isToModify &&
                                _jsxs("div", Object.assign({ className: "flex w-full justify-between", style: {
                                        backgroundColor: "#F5F5FA",
                                        paddingTop: "8px",
                                        paddingBottom: "8px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #E5E5E5",
                                    } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-start gap-2 flex-col font-roboto", style: {
                                                fontWeight: "700",
                                            } }, { children: [_jsx("div", { children: contentDTO.filename }), _jsx("div", Object.assign({ style: {
                                                        fontWeight: "400",
                                                    } }, { children: contentDTO.size }))] })), _jsx("div", Object.assign({ className: "flex w-fit justify-end" }, { children: _jsx("div", Object.assign({ className: "flex w-auto justify-end gap-2" }, { children: _jsx("div", Object.assign({ className: "flex w-full justify-end items-center" }, { children: _jsx("button", Object.assign({ className: "flex w-[30px]rounded-full items-center h-[30px] justify-center", onClick: onDelete }, { children: _jsx(CrossIcon, { color: "black" }) })) })) })) }))] })), !isNullOrUndefined(file) &&
                                _jsxs("div", Object.assign({ className: "flex w-full justify-between", style: {
                                        backgroundColor: "#F5F5FA",
                                        paddingTop: "8px",
                                        paddingBottom: "8px",
                                        paddingLeft: "10px",
                                        paddingRight: "10px",
                                        borderRadius: "5px",
                                        border: "1px solid #E5E5E5",
                                    } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-start gap-2 flex-col font-roboto", style: {
                                                fontWeight: "700",
                                            } }, { children: [_jsx("div", { children: contentDTO.filename }), _jsx("div", Object.assign({ style: {
                                                        fontWeight: "400",
                                                    } }, { children: fileSize }))] })), _jsx("div", Object.assign({ className: "flex w-fit justify-end" }, { children: _jsx("div", Object.assign({ className: "flex w-auto justify-end gap-2" }, { children: _jsx("div", Object.assign({ className: "flex w-full justify-end items-center" }, { children: _jsx("button", Object.assign({ className: "flex w-[30px]rounded-full items-center h-[30px] justify-center", onClick: onDelete }, { children: _jsx(CrossIcon, { color: "black" }) })) })) })) }))] }))] })), _jsxs("div", Object.assign({ className: "flex w-full justify-center", style: {
                            gap: "20px",
                        } }, { children: [_jsx("div", { children: _jsx(FunctionalButton, { fontSize: "12px", text: "Annuler", colorMode: 1, onClick: props.onClose }) }), _jsx("div", { children: _jsx(FunctionalButton, { fontSize: "12px", text: textButton, colorMode: 0, onClick: handleUploadFinish, enabled: isCreateButtonActive }) })] }))] }))] })));
}
export default ContentCreationComponent;
