var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import MenuBar from "../components/MenuBar";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
import EDifficultyCards from "cresus-common/dist/dto/EDifficultyCards";
import LeftIcon from "../components/icons/LeftIcon";
import FunctionalButton from "../components/FunctionalButton";
import CardsTabButton from "../components/CardsTabButton";
import CheckBoxComponent from "../components/CheckBoxComponent";
import TagToFilterComponent from "../components/TagToFilterComponent";
import CardComponent from "../components/CardComponent";
import ECategoriesCards from "cresus-common/dist/dto/ECategoriesCards";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../tools/useAuth";
// import DeckToDemo from "../components/DeckToDemo";
import isNullOrUndefined from "../tools/isNullOrUndefined";
import GeneralToaster from "../components/Toasters/GeneralToaster";
import SelectionCardComponent from "../components/SelectionCardComponent";
import axios from "axios";
export const CARDS_MINIMUM = 25;
function DeckModificationsPage() {
    const { username, userId } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [deck, setDeck] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const [allCards, setAllCards] = useState([]);
    const [selectedType, setSelectedType] = useState(ETypeCards.QUESTION);
    const [selectedCategoriesQuestions, setSelectedCategoriesQuestions] = useState([ECategoriesCards.ASSURANCE, ECategoriesCards.BANQUE, ECategoriesCards.CREDIT, ECategoriesCards.DROIT, ECategoriesCards.EPARGNE, ECategoriesCards.BUDGET, ECategoriesCards.JEU, ECategoriesCards.Q_ECONOMIE]);
    const [selectedCategoriesEvents, setSelectedCategoriesEvents] = useState([ECategoriesCards.CHANCE, ECategoriesCards.DEPENSE, ECategoriesCards.DILEMME, ECategoriesCards.ECONOMIE]);
    const [lowDiff, setLowDiff] = useState(true);
    const [mediumDiff, setMediumDiff] = useState(true);
    const [highDiff, setHighDiff] = useState(true);
    const [nameDeck, setNameDeck] = useState("");
    const [isToModify, setIsToModify] = useState(false);
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [toasterOpen, setToasterOpen] = useState(false);
    const [myDeckBeforeChange, setMyDeckBeforeChange] = useState([]);
    const [numberOfCardsSelectedQuestions, setNumberOfCardsSelectedQuestions] = useState(0);
    const [numberOfCardsSelectedEvents, setNumberOfCardsSelectedEvents] = useState(0);
    const [numberOfCardsSelectedPlaisir, setNumberOfCardsSelectedPlaisir] = useState(0);
    function onCardSelectionChange(card, isSelected) {
        if (isSelected && selectedCards.includes(card) === false) {
            setSelectedCards(selectedCards => [...selectedCards, card]);
        }
        else {
            setSelectedCards(selectedCards.filter(el => el.id !== card.id));
        }
    }
    function getAllCards() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield axios.get(`${process.env.REACT_APP_API_URL}/cards`);
            console.log("CARDS", response.data);
            return response.data;
        });
    }
    function getDefaultDeck() {
        return __awaiter(this, void 0, void 0, function* () {
            const cards = yield getAllCards();
            setDeck(cards);
            setAllCards(cards);
        });
    }
    function returnToDeckList() {
        navigate("/CardsPage");
    }
    function returnFilteredCards(cards) {
        const selectedDifficulties = [];
        if (lowDiff) {
            selectedDifficulties.push(1);
        }
        if (mediumDiff) {
            selectedDifficulties.push(2);
        }
        if (highDiff) {
            selectedDifficulties.push(3);
        }
        let cardsToShow = [];
        if (selectedType === ETypeCards.QUESTION) {
            cardsToShow = cards
                .filter((card) => card.type === ETypeCards.QUESTION)
                .filter((card) => selectedCategoriesQuestions.includes(card.category))
                .filter((card) => selectedDifficulties.includes(card.difficulty));
        }
        else if (selectedType === ETypeCards.EVENT) {
            cardsToShow = cards
                .filter((card) => card.type === ETypeCards.EVENT)
                .filter((card) => selectedCategoriesEvents.includes(card.category));
        }
        else if (selectedType === ETypeCards.PLAISIR) {
            cardsToShow = cards
                .filter((card) => card.type === ETypeCards.PLAISIR);
        }
        return cardsToShow;
    }
    function numberOfCardsSelectedByType(type) {
        return selectedCards.filter(card => card.type === type).length;
    }
    function postNewDeck(deck) {
        return __awaiter(this, void 0, void 0, function* () {
            yield axios.post(`${process.env.REACT_APP_API_URL}/decks`, deck);
        });
    }
    function getDeck(deckId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!isNullOrUndefined(location.state.deckInfos)) {
                const reponse = yield axios.get(`${process.env.REACT_APP_API_URL}/decks/deck/${deckId}`);
                return reponse.data;
            }
            else
                console.log("error, no deck found");
        });
    }
    function modifyDeck(deckId, deck) {
        return __awaiter(this, void 0, void 0, function* () {
            yield axios.put(`${process.env.REACT_APP_API_URL}/decks/${deckId}`, deck);
        });
    }
    function getTheCardsSelectedWithDeck(deckId) {
        return __awaiter(this, void 0, void 0, function* () {
            const MyDeck = yield getDeck(deckId);
            const cards = deck.filter(card => MyDeck.cards.includes(card.id));
            setSelectedCards(cards);
        });
    }
    function onClickModifyDeck() {
        const cardsId = selectedCards.map(card => card.id);
        const deckToSend = {
            name: nameDeck,
            cards: cardsId,
            animatorId: userId,
        };
        if (!isNullOrUndefined(location.state)) {
            const deckInfos = location.state.deckInfos;
            const deckId = deckInfos.deckId;
            modifyDeck(deckId, deckToSend);
        }
        else {
            console.log("error, no deck found");
        }
        navigate("/CardsPage");
    }
    function onClickCreateNewDeck() {
        const cardsId = selectedCards.map(card => card.id);
        const deckToSend = {
            name: nameDeck,
            cards: cardsId,
            animatorId: userId,
        };
        postNewDeck(deckToSend);
        navigate("/CardsPage");
    }
    // const Cards = returnFilteredCards(deck).map((card, index) => {
    //     return (<CardComponent key={"cardItem" + card.type + card.category + card.id} onSelectionChange={onCardSelectionChange} card={card} isSelected={myDeckBeforeChange[card.id-1]} />)
    // })
    const Cards = allCards.map((card, index) => {
        const filteredCards = returnFilteredCards(deck);
        if (filteredCards.includes(card))
            return (_jsx(CardComponent, { display: true, onSelectionChange: onCardSelectionChange, card: card, isSelected: selectedCards.includes(card) }, "cardItem" + card.type + card.category + card.id));
        else
            return (_jsx(CardComponent, { display: false, onSelectionChange: onCardSelectionChange, card: card, isSelected: selectedCards.includes(card) }, "cardItem" + card.type + card.category + card.id));
    });
    const TagToFilterQuestions = [ECategoriesCards.ASSURANCE, ECategoriesCards.BANQUE, ECategoriesCards.CREDIT, ECategoriesCards.DROIT, ECategoriesCards.EPARGNE, ECategoriesCards.BUDGET, ECategoriesCards.JEU, ECategoriesCards.Q_ECONOMIE];
    const TagToFilterQuestionsComponents = TagToFilterQuestions.map((category, index) => {
        return _jsx(TagToFilterComponent, { setCategoriesFilterCards: setSelectedCategoriesQuestions, name: category, isActive: true }, index);
    });
    const TagToFilterEvents = [ECategoriesCards.CHANCE, ECategoriesCards.DEPENSE, ECategoriesCards.DILEMME, ECategoriesCards.ECONOMIE];
    const TagToFilterEventsComponents = TagToFilterEvents.map((category, index) => {
        return _jsx(TagToFilterComponent, { setCategoriesFilterCards: setSelectedCategoriesEvents, name: category, isActive: true }, index);
    });
    const CheckBoxesDifficulty = (_jsxs(_Fragment, { children: [_jsx(CheckBoxComponent, { onChange: (checked) => { setLowDiff(checked); }, name: EDifficultyCards.EASY }), _jsx(CheckBoxComponent, { onChange: (checked) => { setMediumDiff(checked); }, name: EDifficultyCards.MEDIUM }), _jsx(CheckBoxComponent, { onChange: (checked) => { setHighDiff(checked); }, name: EDifficultyCards.HARD })] }));
    useEffect(() => {
        getDefaultDeck();
    }, []);
    useEffect(() => {
        if (!isNullOrUndefined(location.state)) {
            const deckInfos = location.state.deckInfos;
            getTheCardsSelectedWithDeck(deckInfos.deckId);
            setIsToModify(true);
            setNameDeck(deckInfos.name);
        }
    }, [deck]);
    useEffect(() => {
        setNumberOfCardsSelectedQuestions(numberOfCardsSelectedByType(ETypeCards.QUESTION));
        setNumberOfCardsSelectedEvents(numberOfCardsSelectedByType(ETypeCards.EVENT));
        setNumberOfCardsSelectedPlaisir(numberOfCardsSelectedByType(ETypeCards.PLAISIR));
        if (!myDeckBeforeChange.includes(true)) {
            const deckToSet = deck.map(card => {
                if (selectedCards.includes(card))
                    return true;
                else
                    return false;
            });
            setMyDeckBeforeChange(deckToSet);
        }
        console.log('hello');
    }, [selectedCards]);
    function assignPropsToToaster() {
        return {
            message: isToModify ? "Souhaitez-vous sauvegarder les modifications ?" : "Souhaitez-vous créer le deck suivant ?",
            name: nameDeck,
            title: isToModify ? "modifier le deck" : "créer le deck",
            buttonTextCancel: "Annuler",
            buttonTextValidate: isToModify ? "Modifier" : "Créer",
            onClose: () => { setToasterOpen(false); },
            onValidate: isToModify ? () => { onClickModifyDeck(); } : () => { onClickCreateNewDeck(); },
            onCancel: () => { setToasterOpen(false); },
        };
    }
    const toasterProps = assignPropsToToaster();
    function assignPropsToFunctionnalButton() {
        return {
            text: isToModify ? "Sauvegarder" : "Créer le deck",
            colorMode: 0,
            enabled: !(nameDeck === '') && numberOfCardsSelectedQuestions >= CARDS_MINIMUM && numberOfCardsSelectedEvents >= CARDS_MINIMUM && numberOfCardsSelectedPlaisir >= CARDS_MINIMUM,
            isRound: false,
            fontSize: "12px",
            showLeftIcon: false,
            showRightIcon: false,
            onClick: () => { setToasterOpen(true); },
        };
    }
    const functionnalButtonProps = assignPropsToFunctionnalButton();
    useEffect(() => {
        console.log("props", functionnalButtonProps);
    }, []);
    return (_jsxs("div", Object.assign({ className: "h-screen w-screen bg-white  absolute flex flex-col right-0", style: {
            zIndex: -1,
        } }, { children: [toasterOpen && _jsx(GeneralToaster, { message: toasterProps.message, name: nameDeck, title: toasterProps.title, buttonTextCancel: toasterProps.buttonTextCancel, buttonTextValidate: toasterProps.buttonTextValidate, onValidate: toasterProps.onValidate, onCancel: toasterProps.onCancel, onClose: toasterProps.onClose }), _jsxs("div", Object.assign({ className: "h-screen w-screen bg-white flex absolute   flex-col", style: {
                    zIndex: -1,
                } }, { children: [_jsx(MenuBar, { setOpenResetPassword: setOpenResetPassword, selectedTab: 1 }), _jsxs("div", Object.assign({ className: "flex flex-col justify-center relative items-stretch", style: { width: '920px', minWidth: "920px", alignSelf: 'center' } }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center", style: { height: '50px', marginTop: '32px', marginBottom: '16px' } }, { children: [_jsx("div", { children: _jsxs("button", Object.assign({ className: "flex", onClick: returnToDeckList, style: {
                                                gap: '4px',
                                            } }, { children: [_jsx(LeftIcon, {}), _jsx("div", Object.assign({ className: "flex font-roboto text-center self-center items-center", style: {
                                                        fontSize: '12px',
                                                        fontWeight: '700',
                                                        fontStyle: 'normal',
                                                        lineHeight: 'normal',
                                                    } }, { children: "Retour aux decks" }))] })) }), _jsx("div", Object.assign({ className: "flex relative font-roboto text-center text-dilemmeDeepPurpple", style: {
                                            fontSize: '18px',
                                            fontWeight: '700',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            textTransform: 'uppercase',
                                        } }, { children: isToModify ? "informations du deck" : "nouveau deck" })), _jsx(FunctionalButton, { text: functionnalButtonProps.text, colorMode: functionnalButtonProps.colorMode, enabled: functionnalButtonProps.enabled, isRound: functionnalButtonProps.isRound, fontSize: functionnalButtonProps.fontSize, onClick: functionnalButtonProps.onClick })] })), _jsxs("div", Object.assign({ className: "flex flex-col relative justify-start gap-4" }, { children: [_jsxs("div", Object.assign({ className: "flex relative flex-col gap-2" }, { children: [_jsx("div", Object.assign({ className: "font-roboto flex", style: {
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    fontStyle: "normal",
                                                    lineHeight: "normal",
                                                } }, { children: "Titre du deck" })), !isToModify &&
                                                _jsx("input", { className: "flex relative w-1/2", value: nameDeck, onChange: value => setNameDeck(value.target.value), style: {
                                                        backgroundColor: "#F5F5FA",
                                                        paddingTop: "8px",
                                                        paddingBottom: "8px",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                        borderRadius: "4px",
                                                        border: "1px solid #E5E5E5",
                                                    } }), isToModify && _jsx("label", Object.assign({ className: "flex relative w-1/2", style: {
                                                    backgroundColor: "#F5F5FA",
                                                    paddingTop: "8px",
                                                    paddingBottom: "8px",
                                                    paddingLeft: "10px",
                                                    paddingRight: "10px",
                                                    borderRadius: "4px",
                                                    border: "1px solid #E5E5E5",
                                                } }, { children: nameDeck }))] })), _jsxs("div", Object.assign({ className: "flex relative flex-col gap-2" }, { children: [_jsxs("div", Object.assign({ className: "font-roboto flex gap-2" }, { children: [_jsx("div", Object.assign({ className: "font-roboto flex", style: {
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                            fontStyle: "normal",
                                                            lineHeight: "normal",
                                                        } }, { children: "Cartes" })), _jsxs("div", Object.assign({ className: "font-roboto flex", style: {
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            fontStyle: "normal",
                                                            lineHeight: "normal"
                                                        } }, { children: [numberOfCardsSelectedQuestions + numberOfCardsSelectedEvents + numberOfCardsSelectedPlaisir, " cartes selectionn\u00E9es "] }))] })), _jsxs("div", Object.assign({ className: "flex relative gap-2", style: {
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    fontStyle: "italic",
                                                    lineHeight: "normal"
                                                } }, { children: ["Veuillez selectionner ", _jsxs("b", { children: [" au minimum ", CARDS_MINIMUM, " cartes"] }), " de chaque cat\u00E9gorie."] }))] })), _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "flex gap-1" }, { children: [_jsx(CardsTabButton, { cardCount: numberOfCardsSelectedQuestions, type: ETypeCards.QUESTION, selectedType: selectedType, setOpenTypeSelectionCards: setSelectedType }), _jsx(CardsTabButton, { cardCount: numberOfCardsSelectedEvents, type: ETypeCards.EVENT, selectedType: selectedType, setOpenTypeSelectionCards: setSelectedType }), _jsx(CardsTabButton, { cardCount: numberOfCardsSelectedPlaisir, type: ETypeCards.PLAISIR, selectedType: selectedType, setOpenTypeSelectionCards: setSelectedType })] })), _jsx(SelectionCardComponent, { allCards: allCards, cardsComponents: Cards, selectedType: selectedType, typeCards: ETypeCards.QUESTION, tagToFilterComponents: TagToFilterQuestionsComponents, checkBoxComponents: CheckBoxesDifficulty }), _jsx(SelectionCardComponent, { allCards: allCards, cardsComponents: Cards, selectedType: selectedType, typeCards: ETypeCards.EVENT, tagToFilterComponents: TagToFilterEventsComponents }), _jsx(SelectionCardComponent, { allCards: allCards, cardsComponents: Cards, selectedType: selectedType, typeCards: ETypeCards.PLAISIR })] })] }))] }))] }))] })));
}
export default DeckModificationsPage;
