import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../../App.css";
import "../../App.tailwind.css";
import CrossIcon from "../icons/CrossIcon";
import FunctionalButton from "../FunctionalButton";
import isNullOrUndefined from "../../tools/isNullOrUndefined";
function GeneralToaster(props) {
    return (_jsxs("div", Object.assign({ className: " w-screen h-screen flex fixed justify-center", style: {
            opacity: "1",
            zIndex: '10',
        } }, { children: [_jsx("div", { className: "flex absolute bg-dilemmeDeepPurpple w-full h-full ", style: {
                    opacity: "0.8",
                } }), _jsxs("div", Object.assign({ className: "flex flex-col fixed bg-white justify-center self-center items-center", style: {
                    borderColor: "black",
                    borderWidth: "2px",
                    width: "500px",
                    padding: "32px",
                    gap: "24px",
                } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-center" }, { children: [_jsx("div", Object.assign({ className: "font-roboto text-center flex w-full justify-center text-dilemmeDeepPurpple", style: {
                                    fontSize: "18px",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                    textTransform: "uppercase",
                                    fontStyle: "normal",
                                } }, { children: props.title })), _jsx("button", Object.assign({ className: "flex justify-end items-end self-end", onClick: props.onClose }, { children: _jsx(CrossIcon, {}) }))] })), _jsx("div", Object.assign({ className: "flex justify-center text-center font-roboto", style: {
                            fontSize: "16px",
                            lineHeight: "normal",
                            fontStyle: "normal",
                            fontWeight: "400",
                        } }, { children: props.message })), _jsx("div", { children: props.children }), _jsxs("div", Object.assign({ className: "flex flex-col justify-center text-center font-roboto gap-1", style: {
                            fontSize: "16px",
                            lineHeight: "normal",
                            fontStyle: "normal",
                            fontWeight: "600",
                        } }, { children: [_jsx("div", { children: props.name }), _jsx("div", Object.assign({ style: { fontWeight: "400" } }, { children: props.info }))] })), _jsxs("div", Object.assign({ className: "flex flex-row justify-center gap-5" }, { children: [!isNullOrUndefined(props.buttonTextCancel) && _jsx(FunctionalButton, { onClick: props.onCancel, fontSize: "14px", text: props.buttonTextCancel, colorMode: 1, showLeftIcon: false, showRightIcon: false }), !isNullOrUndefined(props.buttonTextValidate) && _jsx(FunctionalButton, { onClick: props.onValidate, fontSize: "14px", text: props.buttonTextValidate, colorMode: 0, showLeftIcon: false, showRightIcon: false })] }))] }))] })));
}
export default GeneralToaster;
