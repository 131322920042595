import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import EyeIcon from "./icons/EyeIcon";
import EyeClosedIcon from "./icons/EyeClosedIcon";
import ECategoriesCards from "cresus-common/dist/dto/ECategoriesCards";
import switchForColorToCategorie from "../tools/switchForColorToCategorie";
export function cardCategoryToText(category) {
    switch (category) {
        case ECategoriesCards.ASSURANCE:
            return "Assurance";
        case ECategoriesCards.BANQUE:
            return "Connaître la banque";
        case ECategoriesCards.CREDIT:
            return "Crédit";
        case ECategoriesCards.DROIT:
            return "Droit et fiscalité";
        case ECategoriesCards.EPARGNE:
            return "Épargne";
        case ECategoriesCards.BUDGET:
            return "Gérer son budget";
        case ECategoriesCards.JEU:
            return "Jeu d'argent";
        case ECategoriesCards.Q_ECONOMIE:
            return "Quelques économies";
        case ECategoriesCards.CHANCE:
            return "Chance";
        case ECategoriesCards.DEPENSE:
            return "Dépense imprévue";
        case ECategoriesCards.DILEMME:
            return "Dilemme";
        case ECategoriesCards.ECONOMIE:
            return "Économies";
        default:
            return "Unknown";
    }
}
function TagToFilterComponent(props) {
    const [isActive, setIsActive] = useState(props.isActive);
    const name = props.name;
    const ref = useRef(null);
    function handleClick() {
        if (ref.current) {
            if (isActive) {
                setIsActive(false);
                props.setCategoriesFilterCards(prev => prev.filter(item => item !== props.name));
            }
            else {
                setIsActive(true);
                props.setCategoriesFilterCards(prev => [...prev, props.name]);
            }
        }
    }
    return (_jsxs("button", Object.assign({ ref: ref, onClick: handleClick, className: "flex gap-1", style: {
            backgroundColor: `${isActive ? switchForColorToCategorie(props.name) : "#E3E3E3"}`,
            borderRadius: "4px",
            padding: "0px 5px",
            color: "white",
        } }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto items-center self-center relative", style: {
                    fontSize: "12px",
                    fontWeight: "600",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    color: `${isActive ? "white" : "#A0A0A0"}`,
                } }, { children: cardCategoryToText(name) })), _jsx("div", Object.assign({ className: "flex items-center self-center relative", style: { height: "18px", width: "18px" } }, { children: isActive ? _jsx(EyeIcon, { color: "white" }) : _jsx(EyeClosedIcon, { color: "#A0A0A0" }) }))] })));
}
export default TagToFilterComponent;
