var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import AuthContext from "../AuthContext";
import Userfront from "@userfront/core";
import EUserRole from "cresus-common/dist/dto/EUserRole";
import TemporaryPasswordUpdatePage from "../pages/TemporaryPasswordUpdatePage";
const AuthProvider = (props) => {
    const [token, setToken] = React.useState(Userfront.tokens.accessToken);
    const [email, setEmail] = React.useState(Userfront.user.email);
    const [username, setUsername] = React.useState(Userfront.user.name);
    const [role, setRole] = React.useState(Userfront.user.hasRole("admin") ? EUserRole.ADMINISTRATOR : EUserRole.ANIMATOR);
    const [openResetPassword, setOpenResetPassword] = useState(false);
    console.log("JWT :" + token);
    console.log('username' + username);
    // TODO request to have up to date infos
    const handlePasswordChange = () => {
        setOpenResetPassword(true);
    };
    const handleLogin = (token) => __awaiter(void 0, void 0, void 0, function* () {
        setToken(token);
    });
    const handleLogout = () => {
        setToken(null);
    };
    const value = {
        token: token,
        email: email,
        userId: Userfront.user.userId,
        username: username,
        role: role,
        onAskPasswordChange: handlePasswordChange,
        onLogin: handleLogin,
        onLogout: handleLogout,
    };
    return (_jsxs(AuthContext.Provider, Object.assign({ value: value }, { children: [openResetPassword && _jsx(TemporaryPasswordUpdatePage, { setOpenResetPassword: () => { setOpenResetPassword(false); } }), props.children] })));
};
export default AuthProvider;
