import { jsx as _jsx } from "react/jsx-runtime";
import ETypeContent from "cresus-common/dist/dto/ETypeContent";
function ContentTypeTag(props) {
    let backgroundColor = "#19A9CF";
    if (props.typeContent === ETypeContent.VIDEO) {
        backgroundColor = "#54659F";
    }
    const typeContent = props.typeContent === ETypeContent.VIDEO ? "Vidéo" : "Image";
    return (_jsx("div", Object.assign({ className: "flex items-center justify-center self-center", style: { padding: '1px 5px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', borderRadius: '2px',
            color: 'white', background: backgroundColor } }, { children: typeContent })));
}
export default ContentTypeTag;
