import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { cardCategoryToText } from "./TagToFilterComponent";
import switchForColorToCategorie from "../tools/switchForColorToCategorie";
import DifficultyIcon from "./icons/DifficultyIcon";
import EDifficultyCards from "cresus-common/dist/dto/EDifficultyCards";
import CardInfoInactiveIcon from "./icons/CardInfoInactiveIcon";
import CardInfoActiveIcon from "./icons/CardInfoActiveIcon";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
function replaceNewLines(text) {
    return text.replaceAll("\\r\\n", "\n");
}
function switchDificultyToColor(eDifficultyCards) {
    switch (eDifficultyCards) {
        case 1:
            return "#47B969";
        case 2:
            return "#FCB35B";
        case 3:
            return "#F16769";
        default:
            return "#000000";
    }
}
function switchDificultyToString(eDifficultyCards) {
    switch (eDifficultyCards) {
        case 1:
            return EDifficultyCards.EASY;
        case 2:
            return EDifficultyCards.MEDIUM;
        case 3:
            return EDifficultyCards.HARD;
        default:
            return "UNKNOWN";
    }
}
function CardComponent(props) {
    const [isChecked, setIsChecked] = useState(props.isSelected);
    const [isInfoCardShowed, setIsInfoCardShowed] = useState(false);
    const name = props.card.title;
    function clickedOnCardInfo() {
        setIsInfoCardShowed(!isInfoCardShowed);
        console.log("isInfoCardShowed ", props.card);
    }
    useEffect(() => {
        setIsChecked(props.isSelected);
        // console.log("props.isSelected ", props.isSelected);
    }, [props.isSelected]);
    function handleChange(event) {
        setIsChecked(event.target.checked);
        if (props.onSelectionChange)
            props.onSelectionChange(props.card, event.target.checked);
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col justify-between", style: {
            display: `${props.display ? "flex" : "none"}`,
            gap: "10px",
            padding: "20px",
            borderRadius: "3px",
            border: "1px solid #6F82C0",
            backgroundColor: `${isChecked ? "#EAEAFF" : "white"}`,
        } }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between w-full" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsx("input", { className: "flex self-center justify-center", type: "checkbox", checked: isChecked, id: name, name: name, onChange: handleChange, style: {
                                    borderRadius: "3px",
                                    border: "1px solid #333333",
                                    width: "16px",
                                    height: "16px",
                                } }), _jsx("div", Object.assign({ className: "font-roboto items-center text-dilemmeDeepPurpple flex relative", style: {
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                } }, { children: name })), props.card.type !== ETypeCards.PLAISIR && _jsx("div", Object.assign({ className: "flex items-center self-center font-roboto relative", style: {
                                    height: "18px",
                                    padding: "0px 5px",
                                    borderRadius: "2px",
                                    backgroundColor: `${switchForColorToCategorie(props.card.category)}`,
                                    color: "white",
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                } }, { children: cardCategoryToText(props.card.category) }))] })), _jsxs("div", Object.assign({ className: "flex gap-6" }, { children: [props.card.type === ETypeCards.QUESTION && _jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto items-center self-center relative", style: {
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                        } }, { children: switchDificultyToString(props.card.difficulty) })), _jsx(DifficultyIcon, { color: switchDificultyToColor(props.card.difficulty) })] })), _jsx("button", Object.assign({ className: "flex", onClick: clickedOnCardInfo, style: {
                                    height: "30px",
                                    width: "30px",
                                } }, { children: isInfoCardShowed ? _jsx(CardInfoActiveIcon, {}) : _jsx(CardInfoInactiveIcon, {}) }))] }))] })), isInfoCardShowed &&
                _jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsx("div", Object.assign({ className: "flex relative flex-wrap font-roboto", style: {
                                fontSize: "14px",
                                fontWeight: "400",
                                fontStyle: "normal",
                                lineHeight: "normal",
                            } }, { children: props.children })), _jsx("div", Object.assign({ className: "flex relative flex-wrap font-roboto", style: {
                                fontSize: "14px",
                                fontWeight: "400",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                whiteSpace: "pre-wrap",
                            } }, { children: replaceNewLines(props.card.textAnimator) })), props.card.comment === "" ? _jsx("div", {}) :
                            _jsxs("div", Object.assign({ className: "flex relative flex-wrap font-roboto", style: {
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    fontStyle: "normal",
                                    lineHeight: "normal",
                                    whiteSpace: "pre-wrap",
                                } }, { children: ["Commentaires : ", replaceNewLines(props.card.comment)] }))] }))] })));
}
export default CardComponent;
