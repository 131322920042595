function CheckPasswordCriteria(password) {
    return CheckPassword_Length(password) &&
        CheckPassword_UpperCase(password) &&
        CheckPassword_LowerCase(password) &&
        CheckPassword_OneDigit(password) &&
        CheckPassword_SpecialCharacter(password);
}
function CheckPassword_Length(password) {
    return password.length >= 8;
}
function CheckPassword_UpperCase(password) {
    return /[A-Z]/.test(password);
}
function CheckPassword_LowerCase(password) {
    return /[a-z]/.test(password);
}
function CheckPassword_OneDigit(password) {
    return /[0-9]/.test(password);
}
function CheckPassword_SpecialCharacter(password) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password);
}
export { CheckPasswordCriteria, CheckPassword_Length, CheckPassword_LowerCase, CheckPassword_UpperCase, CheckPassword_OneDigit, CheckPassword_SpecialCharacter };
