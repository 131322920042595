import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../App.css";
import "../App.tailwind.css";
import { useNavigate } from "react-router-dom";
function ForbiddenPage(props) {
    const navigate = useNavigate();
    function navigateToHomePage() {
        navigate("/ConnectionPage");
    }
    return (_jsxs("div", Object.assign({ className: " w-screen h-screen flex absolute justify-center", style: {
            opacity: "1",
        } }, { children: [_jsx("div", { className: "flex absolute bg-dilemmeDeepPurpple w-full h-full ", style: {
                    opacity: "0.8",
                } }), _jsx("div", Object.assign({ className: "flex absolute w-full justify-center h-full items-center" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col bg-white  items-center", style: {
                        width: "424px",
                        height: "230px",
                        padding: "32px",
                        minWidth: "424px",
                        border: "2px solid #000000",
                        // opacity: "1",
                    } }, { children: [_jsx("div", Object.assign({ className: "flex relative justify-center text-dilemmeDeepPurpple font-roboto  text-center", style: {
                                // height: "5.16%",
                                height: "21px",
                                width: "100%",
                                marginBottom: "24px",
                                // left: "37.91%",
                                fontSize: "18px",
                                lineHeight: "21.9px",
                                fontWeight: "700",
                            } }, { children: "Acc\u00E8s interdit" })), _jsx("button", Object.assign({ onClick: navigateToHomePage, className: "flex relative self-center bg-dilemmeDeepPurpple text-white font-roboto justify-center text-center items-center hover:bg-white hover:text-dilemmeDeepPurpple hover:border-dilemmeDeepPurpple", style: {
                                width: "84.67%",
                                // height: "12.29%",    /* 50px */
                                height: "50px",
                                marginBottom: "24px",
                                marginTop: "24px",
                                fontSize: "14px",
                                fontWeight: "900",
                                lineHeight: "14px",
                                textTransform: "uppercase",
                                fontStyle: "normal",
                                border: "2px solid #000000",
                            } }, { children: "Revenir \u00E0 la page principale" }))] })) }))] })));
}
export default ForbiddenPage;
