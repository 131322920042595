import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import useAuth from "./tools/useAuth";
import { Navigate } from "react-router-dom";
const ProcectedRoute = (props) => {
    const { token } = useAuth();
    if (!token) {
        return _jsx(Navigate, { to: props.redirectTo, replace: true });
    }
    return (_jsx(_Fragment, { children: props.children }));
};
ProcectedRoute.defaultProps = {
    redirectTo: '/ForbiddenPage',
};
export default ProcectedRoute;
