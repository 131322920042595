import { jsx as _jsx } from "react/jsx-runtime";
import EUserRole from "cresus-common/dist/dto/EUserRole";
function UserRoleTag(props) {
    let backgroundColor = "#6F82C0";
    if (props.role === EUserRole.ADMINISTRATOR) {
        backgroundColor = "#7258A5";
    }
    // if (props.role === EUserRole.ANIMATOR) {
    //     return <div></div>
    // }
    return (_jsx("div", Object.assign({ className: "flex items-center justify-center self-center", style: { padding: '1px 5px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: 'bold', borderRadius: '2px',
            color: 'white', background: backgroundColor } }, { children: props.role === EUserRole.ADMINISTRATOR ? "Administrateur" : "Animateur" })));
}
export default UserRoleTag;
