var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CrossIcon from "./icons/CrossIcon";
import { useEffect, useState } from "react";
import axios from "axios";
import isNullOrUndefined from "../tools/isNullOrUndefined";
function VideoPlayer(props) {
    return (_jsx("video", Object.assign({ controls: true }, { children: _jsx("source", { src: props.url, type: "video/mp4" }) })));
}
function ContentVisualisation(props) {
    var _a;
    const [contentModel, setContentModel] = useState(props.content);
    function getContentWithSAS(contentModel) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!isNullOrUndefined(contentModel)) {
                const reponse = yield axios.get(`${process.env.REACT_APP_API_URL}/contents/content/?path=${encodeURIComponent(contentModel.path)}`);
                if (!isNullOrUndefined(reponse))
                    setContentModel(reponse.data);
                else {
                    console.log("no content strange", reponse);
                }
            }
            else {
                console.log("no content");
            }
        });
    }
    useEffect(() => {
        var _a, _b;
        if (!((_a = props.content) === null || _a === void 0 ? void 0 : _a.path.includes('?sv'))) {
            getContentWithSAS(props.content);
        }
        else
            console.log((_b = props.content) === null || _b === void 0 ? void 0 : _b.path.includes('?sv'), 'testets');
    }, []);
    const typeTest = (_a = props.content) === null || _a === void 0 ? void 0 : _a.type;
    return (_jsx("div", Object.assign({ className: "absolute flex w-screen h-screen justify-center items-center", style: {
            zIndex: 100,
            backgroundColor: 'rgba(84, 101, 159, 0.8)',
        } }, { children: _jsxs("div", Object.assign({ className: "flex gap-6 flex-col", style: {
                padding: '32px',
                backgroundColor: '#FFFFFF',
                border: '2px solid #000000',
                width: "675px",
                height: "473px",
            } }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between" }, { children: [_jsx("div", Object.assign({ className: "flex font-roboto text-dilemmeDeepPurpple", style: {
                                fontSize: '18px',
                                fontWeight: "700",
                                textTransform: 'uppercase',
                                lineHeight: '21px',
                            } }, { children: contentModel === null || contentModel === void 0 ? void 0 : contentModel.name })), _jsx("button", Object.assign({ onClick: () => props.onClose(false) }, { children: _jsx(CrossIcon, {}) }))] })), typeTest === 1 ? _jsx("div", Object.assign({ className: "flex", style: {
                        width: "100%",
                        height: "100%",
                    } }, { children: (contentModel === null || contentModel === void 0 ? void 0 : contentModel.path.includes('?sv')) &&
                        _jsx(VideoPlayer, { url: contentModel === null || contentModel === void 0 ? void 0 : contentModel.path }) }))
                    :
                        _jsx("div", { className: "flex", style: {
                                width: "100%",
                                height: "100%",
                                backgroundImage: `url(${contentModel === null || contentModel === void 0 ? void 0 : contentModel.path})`,
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            } })] })) })));
}
export default ContentVisualisation;
