import axios, { AxiosError } from "axios";
import Userfront from "@userfront/core";
axios.interceptors.response.use(undefined, function (error) {
    var _a, _b;
    if (error instanceof AxiosError) {
        const req = (_a = error.response) === null || _a === void 0 ? void 0 : _a.request;
        console.error(`Request failed ${req === null || req === void 0 ? void 0 : req.responseURL} ${JSON.stringify((_b = error.response) === null || _b === void 0 ? void 0 : _b.data)}`);
    }
    throw error;
    // if(error.response.status === 401) {
    //   // ipcRenderer.send('response-unauthenticated');
    //   return Promise.reject(error);
    // }
});
axios.interceptors.request.use(function (config) {
    var _a;
    console.log('interceptor request', config.url, process.env.REACT_APP_API_URL);
    // We only add the token to requests that are sent to our own API
    if ((_a = config.url) === null || _a === void 0 ? void 0 : _a.startsWith(process.env.REACT_APP_API_URL)) {
        console.log('interceptor request config', config.url, process.env.REACT_APP_API_URL);
        const token = Userfront.tokens.accessToken; // TODO check if token is filled by userfront
        console.log(token, 'token hello');
        config.headers.Authorization = "Bearer " + token;
    }
    return config;
});
