"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EColorTag;
(function (EColorTag) {
    EColorTag["PURPLE"] = "#7258A5";
    EColorTag["DARK_BLUE"] = "#54659F";
    EColorTag["LIGHT_BLUE"] = "#007C9C";
    EColorTag["ORANGE"] = "#B75800";
    EColorTag["PINK"] = "#D03073";
    EColorTag["PINK_PURPLE"] = "#C326C7";
    EColorTag["GREEN"] = "#497600";
    EColorTag["GREEN_BLUE"] = "#1E8667";
})(EColorTag || (EColorTag = {}));
exports.default = EColorTag;
