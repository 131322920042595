import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ETypeCards from "cresus-common/dist/dto/ETypeCards";
import { CARDS_MINIMUM } from "../pages/DeckModificationsPage";
function CardsTabButton(props) {
    const active = props.selectedType === props.type;
    const fontWeight = active ? "700" : "normal";
    const cardCount = props.cardCount;
    const zIndex = active ? "200" : "5";
    function handleClick() {
        if (props.type === ETypeCards.QUESTION) {
            props.setOpenTypeSelectionCards(ETypeCards.QUESTION);
        }
        else if (props.type === ETypeCards.PLAISIR) {
            props.setOpenTypeSelectionCards(ETypeCards.PLAISIR);
        }
        else if (props.type === ETypeCards.EVENT) {
            props.setOpenTypeSelectionCards(ETypeCards.EVENT);
        }
        else {
            console.log("error no type selected");
        }
    }
    return (_jsx("div", { children: _jsxs("button", Object.assign({ className: "flex relative flex-row font-roboto", style: {
                gap: '10px',
                backgroundColor: `${active ? "white" : "#E0E0F7"}`,
                border: `${active ? "1px solid black" : "1px solid black"}`,
                borderBottomColor: `${active ? "white" : "black"}`,
                width: "auto",
                height: "100%",
                fontSize: "14px",
                padding: '8px',
                fontWeight: fontWeight,
                justifyContent: "center",
                alignItems: "center",
                zIndex: zIndex,
            }, onClick: handleClick }, { children: [_jsx("div", Object.assign({ className: "flex relative" }, { children: props.type })), _jsx("div", Object.assign({ className: "flex text-center relative self-center items-center justify-center font-roboto", style: {
                        width: "20px",
                        height: "20px",
                        padding: '3px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        fontWeight: '500',
                        backgroundColor: `${cardCount >= CARDS_MINIMUM ? "#47B969" : "#FCB35B"}`,
                    } }, { children: cardCount }))] })) }));
}
export default CardsTabButton;
