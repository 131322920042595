import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './App.css';
import './App.tailwind.css';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import SessionsPage from './pages/SessionsPage';
import ConnectionPage from './pages/ConnectionPage';
import ForgottenPasswordPage from './pages/ForgottenPasswordPage';
import PasswordModificationPage from './pages/PasswordModificationPage';
import TestPage from './pages/TestPage';
import UsersPage from './pages/UsersPage';
import AuthProvider from './components/AuthProvider';
import ProtectedRoute from './ProtectedRoute';
import ForbiddenPage from './pages/ForbiddenPage';
import CardsPage from './pages/CardsPage';
import DeckModificationsPage from './pages/DeckModificationsPage';
import ContentsPage from './pages/ContentsPage';
function App() {
    return (_jsx(AuthProvider, { children: _jsx(Router, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(ConnectionPage, {}) }), _jsx(Route, { path: '/ConnectionPage', element: _jsx(ConnectionPage, {}) }), _jsx(Route, { path: '/ForgottenPasswordPage', element: _jsx(ForgottenPasswordPage, {}) }), _jsx(Route, { path: '/ForbiddenPage', element: _jsx(ForbiddenPage, {}) }), _jsx(Route, { path: '/testpage', element: _jsx(TestPage, {}) }), _jsx(Route, { path: '/PasswordModificationPage', element: _jsx(PasswordModificationPage, {}) }), _jsx(Route, { path: '/SessionsPage', element: _jsx(ProtectedRoute, { children: _jsx(SessionsPage, {}) }) }), _jsx(Route, { path: '/CardsPage', element: _jsx(ProtectedRoute, { children: _jsx(CardsPage, {}) }) }), _jsx(Route, { path: '/DeckModificationsPage', element: _jsx(ProtectedRoute, { children: _jsx(DeckModificationsPage, {}) }) }), _jsx(Route, { path: '/UsersPage', element: _jsx(ProtectedRoute, { children: _jsx(UsersPage, {}) }) }), _jsx(Route, { path: '/ContentsPage', element: _jsx(ProtectedRoute, { children: _jsx(ContentsPage, {}) }) })] }) }) }));
}
export default App;
