import { jsx as _jsx } from "react/jsx-runtime";
function MenuTabButton(props) {
    const fontWeight = props.active ? "700" : "normal";
    const imageName = props.active ? "SelectedTabButton.png" : "TabButton.png";
    const zIndex = props.active ? "100" : "unset";
    function handleClick() {
        if (props.onClick) {
            props.onClick(props.path);
        }
    }
    return (_jsx("div", { children: _jsx("button", Object.assign({ className: "flex relative font-roboto", style: {
                backgroundImage: `url(./assets/UI/${imageName})`,
                backgroundRepeat: "no-repeat",
                backgroundClip: "content-box",
                backgroundSize: "contain",
                fontSize: "12px",
                width: "100px",
                height: "40px",
                fontWeight: fontWeight,
                justifyContent: "center",
                alignItems: "center",
                zIndex: zIndex,
            }, onClick: handleClick }, { children: props.text })) }));
}
export default MenuTabButton;
